import React, { useEffect, useRef, useState } from "react";
import { FaCogs, FaChartLine, FaUsers } from "react-icons/fa";
import { motion } from "framer-motion";

export default function Insight() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        setIsVisible(top < window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  interface Post {
    id: number;
    title: string;
    href: string;
    description: string;
    date: string;
    datetime: string;
    category: { title: string; href: string };
    author: { href: string; imageUrl: string };
    icon: React.ReactNode;
  }

  const posts: Post[] = [
    {
      id: 1,
      title: "School Setup",
      href: "#",
      description:
        "We provide turnkey assistance for the establishment of national and international board K-12 schools including CBSE, ICSE, Cambridge, and IB",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      category: { title: "Education", href: "#" },
      author: {
        href: "#",
        imageUrl: "",
      },
      icon: <FaCogs size={50} style={{ color: "#111827" }} />,
    },
    {
      id: 2,
      title: "School Improvement",
      href: "#",
      description:
        "We undertake school improvement assignments streamlining academics, infrastructure, Training, upgrade school brand position, thereby increasing the rate of admissions.",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      category: { title: "Education", href: "#" },
      author: {
        href: "#",
        imageUrl: "",
      },
      icon: <FaChartLine size={50} style={{ color: "#111827" }} />,
    },
    {
      id: 3,
      title: "School Management",
      href: "#",
      description:
        "We assist promoters of national and international schools to manage their day and residential institutions by streamlining and automation of school functions.",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      category: { title: "Education", href: "#" },
      author: {
        href: "#",
        imageUrl: "",
      },
      icon: <FaUsers size={50} style={{ color: "#111827" }} />,
    },
  ];

  return (
    <div ref={ref}>
      <header className="bg-[#f5f6fa]">
        <div className="rounded-xl p-4 md:p-8">
          <section className="py-12">
            <div className="container mx-auto px-10 max-w-screen-xl">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {posts.map((post, index) => (
                  <motion.article
                    key={post.id}
                    className="flex flex-col items-start"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{
                      opacity: isVisible ? 1 : 0,
                      y: isVisible ? 0 : -50,
                      transition: { duration: 0.7, delay: index * 0.2 },
                    }}
                  >
                    <div className="mb-4">
                      <div className="flex items-center justify-center rounded-full">
                        {post.icon}
                      </div>
                    </div>
                    <div>
                      <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900 ">
                        <a href={post.href}>{post.title}</a>
                      </h3>
                    </div>
                    <motion.div
                      className={`   flex items-center  transition-transform transform col-span-1 row-span-1 min-w-0 ${
                        index < 3 ? "first-three" : ""
                      }`}
                    >
                      <div className="mt-6 text-md leading-8 text-gray-600">
                        {post.description}
                      </div>
                    </motion.div>
                  </motion.article>
                ))}
              </div>
            </div>
          </section>
        </div>
      </header>
    </div>
  );
}
