import React, { useEffect } from "react";
import { motion } from "framer-motion";
import blog from "./Assets/Images/blog.png";
import Consultancy from "./Consultancy";
const ServOffer = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div className="relative py-12">
        <div className="relative h-96 md:h-auto">
          <img
            src="https://pointlinklogistics.com/images/2018/11/05/banner-our-services-1443074773.png"
            className="w-full h-full object-cover"
            alt="Background"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black "></div>
        </div>

        <div className="absolute top-3/4 bottom-4/6 inset-x-0 transform translate-y-2/2 flex justify-center items-center">
          <img
            src={blog}
            className="max-w-full h-auto object-cover "
            alt="Contact Us Banner"
          />
        </div>
      </div>
      <motion.div
        className="container mx-auto px-12 max-w-screen-xl flex flex-col items-center justify-center mb-10 z-[5]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="text-left z-[5]">
          <motion.h2
            className="text-3xl font-extrabold text-left mb-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <h2 className="text-3xl font-extrabold text-left mb-4">
              Services we offer
            </h2>
          </motion.h2>
          <motion.p
            className="text-lg z-[5]"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 1 }}
          >
            <p className="text-lg">
              Explore the wide range of services we offer and unlock a world of
              possibilities. Our tailored solutions are designed to transform
              your experience and empower you to accomplish more.
            </p>{" "}
          </motion.p>
        </div>

        <div className="mt-16">
          <div className="grid md:grid-cols-2 items-center gap-16 z-[5]">
            <motion.div
              className="z-[5]"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <div className="z-[5]">
                <img
                  src="https://images.unsplash.com/photo-1580582932707-520aed937b7b?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2Nob29sc3xlbnwwfHwwfHx8MA%3D%3D"
                  className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                />
              </div>
            </motion.div>
            <motion.div
              className=""
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
            >
              <div>
                <p className="text-lg">
                  We help schools receive their recognition with International
                  boards such as CIE, IB and Edexcel and national boards such as
                  CBSE and ICSE. This involves preparation of application forms
                  and technical reports for affiliations and preparing for
                  inspections and assist in compliance with requirements of
                  affiliation and the law of the land.
                </p>
              </div>
            </motion.div>
            <div className="max-md:order-1">
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <p className="text-lg">
                  We enthusiastically work with schools in developing a
                  tailor-made curriculum basing closely on needs of the children
                  in the area and the international key stage standards. We
                  involve in guidance to management, principals, coordinates and
                  teachers and staff. Curriculum handholding in our expertise.
                  We develop schools to an international standard and aim to
                  receive the British Council International School Award.
                </p>
              </motion.div>
            </div>
            <motion.div
              className=""
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
            >
              <div>
                <img
                  src="https://cabl.org/wp-content/uploads/2022/06/teacher-stock-photo.jpg"
                  className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                />
              </div>
            </motion.div>
          </div>
          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-16">
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div>
                  <img
                    src="https://media.istockphoto.com/id/171306436/photo/red-brick-high-school-building-exterior.jpg?s=612x612&w=0&k=20&c=vksDyCVrfCpvb9uk4-wcBYu6jbTZ3nCOkGHPSgNy-L0="
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <p className="text-lg">
                    We assist schools procure top quality school supplies like
                    play school furniture, classroom furniture, Montessori
                    school materials, technology like school management
                    software’s, projectors, computers, interactive boards.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div className="max-md:order-1">
                  <p className="text-lg">
                    We design tailor made STEM program which fits straight into
                    the curriculum and help develop an international standard
                    STEM Laboratory.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <img
                    src="https://core-docs.s3.amazonaws.com/holyoke_public_schools_ar/article/image/large_5776c896-d7e7-4d68-8202-3431dfc5d75f.jpg"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-16">
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div>
                  <img
                    src="https://media.istockphoto.com/id/1482757928/photo/earth-globe-model-ball-map-with-class-room-background-on-tablet-in-classroom-concept-for.jpg?s=612x612&w=0&k=20&c=kU0h4SRPb7ELaHPJQo1GrXZJY0lcjtNROWRwwbsrm38="
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <p className="text-lg">
                    We are the only Educational Consultation to tie up with
                    National Schools Observatory - Astrophysics Research
                    University under the Liverpool John Moores University, UK.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div className="max-md:order-1">
                  <p className="text-lg">
                    We provide advisory for schools in marketing and branding of
                    the school. Olety’s assists the school in advertisings.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <img
                    src="https://verbaljudo.com/wp-content/uploads/2024/02/Education-2024.jpg"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-16">
              {" "}
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div>
                  <img
                    src="https://images.pexels.com/photos/1438072/pexels-photo-1438072.jpeg?auto=compress&cs=tinysrgb&w=600"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <p className="text-lg">
                    Insight Eduzone provide guidance and strategic advisory for
                    schools with regards to goals and milestones for the school.
                    We advice on best policy practice, procedures and
                    communication systems to create a safe environment for
                    students.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div className="max-md:order-1">
                  <p className="text-lg">
                    . Library management, developing library resources,
                    procurement of world renowned textbooks, and inculcating of
                    books into the curriculum frameworks are our expertise.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <img
                    src="https://images.pexels.com/photos/1370296/pexels-photo-1370296.jpeg?auto=compress&cs=tinysrgb&w=600"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-16">
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div>
                  <img
                    src="https://images.pexels.com/photos/2781814/pexels-photo-2781814.jpeg?auto=compress&cs=tinysrgb&w=600"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <p className="text-lg">
                    We dedicate enormous amount of time, effort and resources in
                    research of STEM labs and activities and have thus far
                    developed a resources compilation of over 200 STEM
                    activities for students of K-12.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div className="max-md:order-1">
                  <p className="text-lg">
                    We help develop experiential education activities such as
                    field trips or international educational travel programs for
                    students with a vision of transforming learning through
                    experiential learning.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <img
                    src="https://images.pexels.com/photos/3184432/pexels-photo-3184432.jpeg?auto=compress&cs=tinysrgb&w=600"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-16">
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div>
                  <img
                    src="https://images.pexels.com/photos/1205651/pexels-photo-1205651.jpeg?auto=compress&cs=tinysrgb&w=600"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <p className="text-lg">
                    Not only do we develop and assist school managements we also
                    closely involve with the children in career development.
                    Psychometric Test , Skill Assessment and Career Guidance for
                    children to help them identify their strengths and
                    weaknesses.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div className="max-md:order-1">
                  <p className="text-lg">
                    We tie up with International Universities in UK, US and
                    Europe and provide assistance to students in international
                    university admissions and provide trainings for the related
                    examinations.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div>
                  <img
                    src="https://images.pexels.com/photos/207684/pexels-photo-207684.jpeg?auto=compress&cs=tinysrgb&w=600"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>

    </div>
  );
};

export default ServOffer;
