import React, { useState } from "react";
import { motion } from "framer-motion";

export default function Mission() {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div>
      <div
        id="knowus#mission-vision"
        className="container mx-auto px-4 lg:px-40 py-5"
      >
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-1/2 z-[5]">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={
                isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
              }
              transition={{ duration: 1 }}
            >
              <div className="text-center lg:text-left">
                <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold mb-5 text-[#2D7AB7]">
                  <span className="text-">Our Mission</span>
                </h1>
                <p className="mt-6 text-sm lg:text-sm leading-1 text-gray-600 text-justify max-w-sm lg:max-w-md">
                  Our mission is to provide unwavering support to clients who
                  aspire for growth and success. We prioritize empowering their
                  teams with confidence, guiding them every step of the way
                  towards achieving their goals. Through personalized assistance
                  and steadfast encouragement, we aim to ensure our clients'
                  success in their educational endeavors.
                </p>
              </div>
            </motion.div>
          </div>
          <div className="w-full lg:w-1/2 mt-8 lg:mt-0 z-[5] ">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={
                isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
              }
              transition={{ duration: 1 }}
            >
              <motion.div
                className="transform transition duration-2000 hover:scale-105"
                whileHover={{ scale: 1.05 }}
                style={{ padding: "10px" }}
              >
                <img
                  src="https://img.freepik.com/free-vector/flat-our-mission-infographic_23-2149065435.jpg?size=626&ext=jpg&ga=GA1.1.629983002.1709806369&semt=ais"
                  alt="Description of your image"
                  className="h-auto w-full object-cover bg-white rounded-lg"
                />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 lg:px-40 py-10">
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-1/2 mt-8 lg:mt-0 z-[5]">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={
                isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
              }
              transition={{ duration: 1 }}
            >
              <motion.div
                className="transform transition duration-2000 hover:scale-105"
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src="https://img.freepik.com/free-vector/business-vision-concept-illustration_114360-7130.jpg?t=st=1709882878~exp=1709886478~hmac=21b7094f314aa3838bd87cdd922345f6f1d2a0db58fae1a1366a016220d22555&w=740"
                  alt="Description of your image"
                  className="h-auto object-cover bg-white rounded-lg"
                />
              </motion.div>
            </motion.div>
          </div>
          <div className="w-full lg:w-1/2 z-[5]">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={
                isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
              }
              transition={{ duration: 1 }}
            >
              <div className="text-center lg:text-left">
                <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold mb-5 text-[#86c753]">
                  <span className="text-">Our Vision</span>
                </h1>
                <p className="mt-6 text-sm lg:text-sm leading-1 text-gray-600 text-justify">
                  Our Vision is to equip every individual with the power of
                  knowledge. Whether you're a student, a teacher, or part of the
                  management team, we're dedicated to providing unparalleled
                  support in the education industry. Our aim is to deliver the
                  best possible services to clients who seek our assistance,
                  ensuring that everyone has access to exceptional educational
                  opportunities.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
