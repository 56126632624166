import React, { useState } from "react";
import { motion } from "framer-motion";

export default function Values() {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div id="knowus#Values-value" className=" flex justify-center py-5">
      <div className="items-start justify-center">
        <div className="text-justify py-5">
          <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold mb-5 text-[#2D7AB7]">
            <span className="text-"> Our Values</span>
          </h1>
          <p>
            Our core values reflect the basic principles that guide our
            interactions with every stakeholder of the organization.At Shri
            Educare Limited, values are not just strong words. These are a
            collection of habits that should be reflected in our day-to-day
            behavior.
          </p>
        </div>

        <div className="">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 1 }}
          >
            <div className="mb-8 flex items-center">
              <div className="flex-shrink-0 mr-4">
                <img
                  src="https://shrieducare.com/assets/images/fck_filesimages/1_customer-first.jpg"
                  alt=""
                  className="w-auto h-60 rounded-lg"
                />
              </div>
              <div className="ml-4 text-black">
                <h1>
                  <strong>Customer First</strong>
                </h1>
                <p>
                  We prioritize our customers and strive to provide them with
                  the best services possible. Our commitment to customer
                  satisfaction drives all our interactions.
                </p>
              </div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="ml-4 text-black text-justify">
                <h1>
                  <strong>Excellence</strong>
                </h1>
                <p>
                  We promote excellence both individually and collectively. Our
                  dedication to continuous improvement fosters personal growth
                  and drives achievement.
                </p>
              </div>
              <div className="flex-shrink-0 ml-5">
                <img
                  src="https://shrieducare.com/assets/images/fck_filesimages/2_Exellence.jpg"
                  alt=""
                  className="w-auto h-60 rounded-lg"
                />
              </div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-shrink-0 mr-4">
                <img
                  src="https://shrieducare.com/assets/images/fck_filesimages/3_innovation.jpg"
                  alt=""
                  className="w-auto h-60 rounded-lg"
                />
              </div>
              <div className="ml-4 text-black text-justify">
                <h1>
                  <strong>Innovation</strong>
                </h1>
                <p>
                  We foster creativity and encourage innovative thinking across
                  all aspects of our organization. Embracing new ideas propels
                  us towards success.
                </p>
              </div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="ml-4 text-black text-justify">
                <h1>
                  <strong>Speed</strong>
                </h1>
                <p>
                  "We prioritize agility and responsiveness, engaging
                  transparently with stakeholders for sustainable growth through
                  continuous improvement."
                </p>
              </div>
              <div className="flex-shrink-0 ml-5">
                <img
                  src="https://shrieducare.com/assets/images/fck_filesimages/4_speed.jpg"
                  alt=""
                  className="w-auto h-60 rounded-lg"
                />
              </div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-shrink-0 mr-4">
                <img
                  src="https://shrieducare.com/assets/images/fck_filesimages/5_integrity.jpg"
                  alt=""
                  className="w-auto h-60 rounded-lg"
                />
              </div>
              <div className="ml-4 text-black text-justify">
                <h1>
                  <strong>Integrity</strong>
                </h1>
                <p>
                  "We maintain the highest ethical standards, ensuring
                  transparency in all transactions, reflecting our commitment to
                  trust and credibility."
                </p>
              </div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="ml-4 text-black text-justify">
                <h1>
                  <strong>Respect</strong>
                </h1>
                <p>
                  We value diversity and treat everyone with respect and
                  dignity. Open communication and mutual respect form the
                  foundation of our interactions.
                </p>
              </div>
              <div className="flex-shrink-0 ml-5">
                <img
                  src="https://shrieducare.com/assets/images/fck_filesimages/6_respect.jpg"
                  alt=""
                  className="w-auto h-60 rounded-lg"
                />
              </div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-shrink-0 mr-4">
                <img
                  src="https://shrieducare.com/assets/images/fck_filesimages/7_well-being.jpg"
                  alt=""
                  className="w-auto h-60 rounded-lg"
                />
              </div>
              <div className="ml-4 text-black text-justify">
                <h1>
                  <strong>Well-being</strong>
                </h1>
                <p>
                  "We prioritize employee well-being, fostering a supportive
                  environment and promoting work-life balance."
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
