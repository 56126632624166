import React, { useEffect } from "react";
import blog from "./Assets/Images/blog.png";
import Buy from "./Assets/Images/buy.jpg";
import Selling from "./Assets/Images/Start-Selling1.jpg";
import { motion } from "framer-motion";
export default function SchoolSetUp() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div className="relative py-20">
        <div className="relative h-96 md:h-auto">
          <img
            src="https://www.cdaschools.org/cms/lib/ID01906304/Centricity/Domain/415/school%20supply%20lists%20banner-833.png"
            className="w-full h-full object-cover"
            alt="Background"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black "></div>
        </div>

        <div className="absolute top-3/4 bottom-4/6 inset-x-0 transform translate-y-2/2 flex justify-center items-center">
          <img
            src={blog}
            className="max-w-full h-auto object-cover "
            alt="Contact Us Banner"
          />
        </div>
      </div>
      <motion.div
        className="container mx-auto px-12 max-w-screen-xl flex flex-col items-center justify-center mb-10 z-[5]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="text-left z-[5]">
          <motion.h3
            className="text-3xl  text-left mb-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <h3 className="text-3xl  text-left mb-4">
              One Stop Shop for Buying School Supplies Online
            </h3>
          </motion.h3>
          <motion.p
            className="text-lg z-[5]"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 1 }}
          >
            <p className="text-lg">
              eduzoneonline.com is the world’s first hyperlocal marketplace
              for Schools. Schools can find wide range of school supplies and
              buy playschool furniture, classroom furniture, toys for school,
              teaching aid, learning aid, preschool material, Montessori
              material, school stationery, Modern classroom technology like
              projectors, computers, interactive whiteboards and many more.
            </p>{" "}
          </motion.p>
        </div>
        <div className="z-[5] mb-4 py-4">
          <a href="https://eduzoneonline.com/" target="_blank">
            <motion.h3
              className="text-3xl  text-left mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <img
                className="school_supply_img"
                src="https://eduzoneonline.com/wp-content/uploads/2022/06/eduzonelogo.png"
                alt="School supplies and materials"
              />
            </motion.h3>
          </a>
        </div>
        <div className="z-[5] mb-4 py-4">
          <motion.p
            className="text-lg z-[5]"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 1 }}
          >
            <img src="https://www.schoolserv.in/media/banner_image/Banner_1.jpg" />
          </motion.p>
        </div>
        <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-1/3 p-4">
            <motion.h3
              className="text-3xl  text-left mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <div className="services-box">
                <span className="icon">
                  <img src="https://www.schoolserv.in/static/images/header_img/onestopschoolshop-1.png" />
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/onestopschoolshop-1.png"
                    className="white-image"
                    alt="Consumer Protection"
                  />
                </span>
                <div className="service-content">
                  <h3 className="text-3xl  text-left mb-4">
                    One Stop School Shop
                  </h3>
                  <p className="text-lg">
                    Single window for all school Supplies, Solutions and
                    Services.
                  </p>
                </div>
              </div>
            </motion.h3>
            <motion.h3
              className="text-3xl  text-left mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <div className="services-box">
                <span className="icon">
                  <img src="https://www.schoolserv.in/static/images/header_img/consumer-protection-1.png" />
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/consumer-protection-1.png"
                    className="white-image"
                    alt="Consumer Protection"
                  />
                </span>
                <div className="service-content">
                  <h3 className="text-3xl  text-left mb-4">
                    Consumer Protection
                  </h3>
                  <p className="text-lg">
                    Get full refund, if you did not receive the goods.
                  </p>
                </div>
              </div>
            </motion.h3>
            <motion.h3
              className="text-3xl  text-left mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <div className="services-box">
                <span className="icon">
                  <img src="https://www.schoolserv.in/static/images/header_img/secure-transaction-1.png" />
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/secure-transaction-1.png"
                    className="white-image"
                    alt="Secure Transactions"
                  />
                </span>
                <div className="service-content">
                  <h3 className="text-3xl  text-left mb-4">
                    Secure Transactions
                  </h3>
                  <p className="text-lg">
                    Without your consent, we will not deliver your money to the
                    seller.
                  </p>
                </div>
              </div>
            </motion.h3>
          </div>

          <div className="w-full md:w-1/3 p-4 flex items-center justify-center">
            <motion.p
              className="text-lg z-[5]"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
            >
              <img
                className="mx-auto md:mx-0 rounded text-white"
                src="https://images.pexels.com/photos/2675061/pexels-photo-2675061.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
              />
            </motion.p>
          </div>
          <div className="w-full md:w-1/3 p-4">
            <motion.h3
              className="text-3xl  text-left mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <div className="services-box">
                <span className="icon">
                  <img src="https://www.schoolserv.in/static/images/header_img/Quick-Delivery-1.png" />
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/Quick-Delivery-1.png"
                    className="white-image"
                    alt="Quick Delivery"
                  />
                </span>
                <div className="service-content">
                  <h3 className="text-3xl  text-left mb-4">Quick Delivery</h3>
                  <p className="text-lg">
                    Delivered by the seller directly to your School.
                  </p>
                </div>
              </div>
            </motion.h3>
            <div className="services-box">
              <span className="icon">
                <img src="https://www.schoolserv.in/static/images/header_img/return-and-replacements-1.png" />
                <img
                  src="https://www.schoolserv.in/static/images/header_img/return-and-replacements-1.png"
                  className="white-image"
                  alt="Easy Returns & Replacements"
                />
              </span>
              <div className="service-content">
                <h3 className="text-3xl  text-left mb-4">
                  Easy Returns & Replacements
                </h3>
                <p>
                  Verify goods upon delivery. If not as described on
                  eduzoneonline, return for replacement.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <motion.p
              className="text-lg z-[5]"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
            >
              <img src="https://www.schoolserv.in/static/images/header_img/how-it-works.png" />
            </motion.p>
          </div>
        </div>{" "}
        <div className="flex justify-around items-center  rounded bg-white">
          <div>
          <a
            href="https://eduzoneonline.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img src={Buy} alt="Start Selling" />
            </div>
          </a>
          </div>
        </div>
      </motion.div>
      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-1/2 lg:w-1/2 px-4 md:px-7 flex flex-col md:flex-row items-center">
          <img
            src="https://www.schoolserv.in/static/images/header_img/cart.png"
            className="mx-auto mb-4 md:mb-0"
            alt="Cart Image"
          />
          <div className="text-justify">
            <p className="mb-4">
              Brands showcase their products to schools across India, empower
              their distributors / resellers with an additional 24×7 online
              sales channel, grow revenue by selling online to schools across
              major cities in India.
            </p>
            <p className="mb-4">
              Sellers registered on the platform rely on their inherent
              logistics and support system to deliver school supplies and
              provide hassle-free post-sales support to schools.
              eduzoneonline.com enables online sales to schools without
              disrupting your focus on client satisfaction and helps realize
              revenues much faster when compared to direct to school sales.
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/2 px-4 gap-10 md:px-5 flex flex-col md:flex-row items-center mt-4 md:mt-0">
          <div className="text-justify">
            <p className="mb-4">
              We began in K-12 schools as a supplies aggregator. Our team later
              led direct sales, delivering supplies to Hundreds of schools
              across Andhra Pradesh, Karnataka, Telangana, Madhya Pradesh,
              Maharashtra, Tamil Nadu, and Kerala.
            </p>
            <p className="mb-4">
              eduzoneonline.com is born out of passion to complement the
              ‘direct to school sales’ channel, where sustaining a dedicated
              sales and marketing team is a daily challenge. Further, it is
              equipped with our learnings from the school supplies market in
              India and commendable subject market expertise in the sector.
            </p>
          </div>
          <img
            src="https://www.schoolserv.in/static/images/header_img/box.png"
            className="mx-auto mt-4 md:mt-0"
            alt="Box Image"
          />
        </div>
      </div>

      <div className="flex justify-center mb-4">
        <div>
          <a
            href="https://eduzoneonline.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img src={Selling} alt="Start Selling" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
