import React, { useState, useEffect } from "react";
import scllogo from "./Assets/Images/scllogo.png";
import { HashLink as Link } from "react-router-hash-link";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header
      className={`fixed top-0 w-full z-50 ${
        isScrolled
          ? "shadow-lg bg-white text-black"
          : "bg-white text-black shadow-sm"
      }`}
    >
      <div className="container mx-auto  bg-transparent flex flex-wrap items-center py-4 justify-around md:flex-nowrap">
        <Link
          to="/"
          className="text-md hover:bg-gray-100 rounded-md p-2 flex items-center"
        >
          <div className="ml-4 md:ml-10 bg-transparent">
            <img src={scllogo} className="h-10 max-w-full" alt="Logo" />
          </div>
        </Link>

        <div
          className="pointer-cursor md:hidden block bg-gray-900 rounded p-2"
          onClick={toggleMenu}
        >
          <svg
            className={`fill-current ${
              isScrolled ? "text-gray-700" : "text-white"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </div>

        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } md:block md:items-center md:w-auto w-full justify-end`}
          id="menu"
        >
          <nav>
            <ul className="md:flex md:items-center text-md  pt-4 md:pt-0 justify-evenly gap-0">
              <li
                className={`hover:text-[#86c753] ${
                  isScrolled ? "text-black" : ""
                }`}
              >
                <Link
                  to="/"
                  className="md:p-4 py-3 px-0 block"
                  onClick={closeMenu}
                >
                  Home
                </Link>
              </li>
              <li className="relative group hover:text-[#86c753] ">
                <Link to="/knowus" className="md:p-4 py-3 px-0 block md:flex">
                  About
                  <span className="dropdown-arrow text-md ">&#9660;</span>
                </Link>

                <ul className="dropdown-menu hidden md:absolute md:bg-white md:text-gray-700 md:pt-2">
                  <li>
                    <Link
                      to="/knowus"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      Insight Eduzone
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/knowus#mission-vision"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      Mission & Vision
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/knowus#Values-value"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      Values
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="relative group">
                <Link
                  to="#"
                  className="md:p-4 py-3 px-0 block hover:text-[#86c753] md:flex"
                >
                  Services{" "}
                  <span className="dropdown-arrow text-md">&#9660;</span>
                </Link>
                <ul className="dropdown-menu hidden md:absolute md:bg-white md:text-gray-700 md:pt-2 ">
                  <li>
                    <Link
                      to="/service"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      Services We Offer
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/consult"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      Consultancy process
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/knowus#leadership-teams"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      Leadership Teams
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="relative group">
                <Link
                  to="#"
                  className="md:p-4 py-3 px-0 block hover:text-[#86c753] md:flex"
                >
                  Clients{" "}
                  <span className="dropdown-arrow text-md">&#9660;</span>
                </Link>
                <ul className="dropdown-menu hidden md:absolute md:bg-white md:text-gray-700 md:pt-2">
                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      List of notable clients
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/newproject"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                      Project profiles
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="relative group">
                <Link
                  to="#"
                  className="md:p-4 py-3 px-0 block  hover:text-[#86c753] md:flex"
                >
                  AssociatePartners
                  <span className="dropdown-arrow text-md">&#9660;</span>
                </Link>
                <ul className="dropdown-menu hidden md:absolute md:bg-white md:text-gray-700 md:pt-2">
                  <li>
                    <Link
                      to="/sclsetup"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                     School Supplies
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/hire"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                     Hire Teachers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sclforte"
                      className="block px-4 py-2"
                      onClick={closeMenu}
                    >
                   Scholl ERP
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="relative group">
                <Link
                  to="/"
                  className="md:p-4 py-3 px-0 block hover:text-[#86c753] md:flex"
                >
                  Career
                </Link>
              </li>
              <li className="relative group">
                <Link
                  to="/contactus"
                  className="md:p-4 py-3 px-0 block hover:text-[#86c753] md:flex"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
