import React, { useEffect } from "react";
import blog from "./Assets/Images/blog.png";
import { motion } from "framer-motion";
import NewProject from "./NewProject";

export default function Consultancy() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      {" "}
      <div>
        <div className="relative py-12">
          <div className="relative h-96 md:h-auto">
            <img
              src="https://img.freepik.com/premium-vector/consulting-banner-web-icon-business_35632-115.jpg?w=2000"
              className="w-full h-full object-cover"
              alt="Background"
            />
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black "></div>
          </div>

          <div className="absolute top-3/4 bottom-4/6 inset-x-0 transform translate-y-2/2 flex justify-center items-center">
            <img
              src={blog}
              className="max-w-full h-auto object-cover "
              alt="Contact Us Banner"
            />
          </div>
        </div>
        <motion.div
          className="container mx-auto px-12 max-w-screen-xl flex flex-col items-center justify-center mb-10 z-[5]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="text-left z-[5]">
            <motion.h2
              className="text-3xl font-extrabold text-left mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <h2 className="text-3xl font-extrabold text-left mb-4">
                Consultancy
              </h2>
            </motion.h2>
            <motion.p
              className="text-lg z-[5]"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
            >
              <p className="text-lg">
                Discover our extensive range of consultancy services and open
                the door to a world of possibilities. Our customized solutions
                are crafted to enhance your journey and empower you to achieve
                greater success.
              </p>{" "}
            </motion.p>
          </div>

          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-16 z-[5]">
              <motion.div
                className="z-[5]"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div className="z-[5]">
                  <img
                    src="https://img.freepik.com/free-photo/young-happy-couple-meeting-with-financial-advisor-office-woman-is-handshaking-with-agent_637285-3904.jpg?size=626&ext=jpg&ga=GA1.1.1031325081.1711011800&semt=sph"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <p className="text-lg">
                    School consultancy services – setting up PreK-K12, mergers
                    and acquisitions, end-to-end school consultancy provider We
                    are at the forefront of K12 school consultancy in India,
                    uniquely equipped with in-house capabilities to address the
                    full spectrum of establishment and management needs within
                    the education sector. Our outreach is unparalleled, and we
                    consistently demonstrate a high level of commitment and
                    engagement with every school we assist in establishing
                    across India.
                  </p>
                </div>
              </motion.div>
              <div className="max-md:order-1">
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                >
                  <p className="text-lg">
                    Our dedication to advancing education is evident in our
                    adept use of technology and the development of specialized
                    platforms tailored to address critical aspects of school
                    management, setup and enhancement. While our core services
                    are intensive and benefits select school partners, our
                    online services are extensive, reaching thousands of schools
                    nationwide. Our platforms for teacher recruitment, school
                    real estate, school supplies, marketplace and school ERP
                    systems cater to schools of all sizes, promoting efficiency
                    in time and cost management.
                  </p>
                </motion.div>
              </div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <img
                    src="https://img.freepik.com/free-photo/businessman-showing-changes-report_1098-3504.jpg?size=626&ext=jpg&ga=GA1.1.1031325081.1711011800&semt=sph"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
            </div>

            <div className="mt-16">
              <div className="grid md:grid-cols-2 items-center gap-16">
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                >
                  <div>
                    <img
                      src="https://img.freepik.com/free-photo/co-workers-talking-office_1098-2051.jpg?size=626&ext=jpg&ga=GA1.1.1031325081.1711011800&semt=sph"
                      className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                    />
                  </div>
                </motion.div>
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 1 }}
                >
                  <div>
                    <p className="text-lg">
                      At the heart of our approach lies a firm belief in
                      research and development with the track record of
                      introducing annual innovations to the educational
                      landscape. Our reputation for dynamism stems from our
                      ability to assist schools in adapting to the demands of
                      the 21st century educational ecosystem, while adhering to
                      enduring principles.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 1 }}
                >
                  <div className="max-md:order-1">
                    <p className="text-lg">
                      India's premier K12 school services company provides
                      comprehensive support for new school projects, including
                      market assessment, project reports, infrastructure
                      planning, academic program development, branding,
                      marketing strategy, teacher recruitment, training, and
                      procurement. Our team comprises consultants, academicians,
                      recruiters, trainers, architects, brand specialists,
                      marketing specialists, project managers, software
                      engineers, chartered accountants, and school suppliers. We
                      aim to ensure exemplary school development experiences and
                      collaborate with international consultants for IB World
                      Schools, utilizing renowned architects and designers for
                      innovative concepts.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                >
                  <div>
                    <img
                      src="https://img.freepik.com/free-photo/businessmen-elegant-suits-business-meeting-discussing-new-project-office_181624-47022.jpg?size=626&ext=jpg&ga=GA1.1.1031325081.1711011800&semt=sph"
                      className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                    />
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
