import React from "react";
import Home from "./Home";
import SchoolSet from "./SchoolSet";
import Influencing from "./Influencing";
import Partners from "./Partners";


export default function Main() {
  return (
    <div className="">
      {" "}
      <Home />
      <div className="py-14">
        <SchoolSet />
      </div>
      <Influencing />
      <Partners />{" "}
 
    </div>
  );
}
