import React, { useEffect } from "react";
import blog from "./Assets/Images/blog.png";
import { motion } from "framer-motion";
export default function HireTeachers() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div className="relative py-20 ">
        <div className="relative h-96 md:h-auto">
          <img
            src="https://www.eapl.org/sites/default/files/2021-08/teacher%20header-01.jpg"
            className="w-full h-full object-cover"
            alt="Background"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black "></div>
        </div>

        <div className="absolute top-3/4 bottom-4/6 inset-x-0 transform translate-y-2/2 flex justify-center items-center">
          <img
            src={blog}
            className="max-w-full h-auto object-cover "
            alt="Contact Us Banner"
          />
        </div>
      </div>
      <section className=" max-w-7xl mx-auto px-4 sm:px-6 lg:px-4">
        <div className="my-10 mx-auto max-w-7xl px-4    flex gap-3 lg:flex-justify lg:flex flex-col lg:flex-row">
          <motion.h3
            className="text-3xl  text-left mb-4 z-[5]"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <div className="sm:text-center lg:text-left z-[5]">
              <h1 className="text-3xl tracking-tight font-extrabold text-gray-800 ">
                <span className="block mb-4">
                  <div className="z-[5]">
                    <img src="https://www.schoolserv.in/static/images/header_img/eo_logo.png" />
                  </div>
                </span>
                <span className="block text-3xl text-left">
                  educatorOne.com is an exclusive teaching jobs search site.
                </span>
              </h1>
              <p className="mt-3 text-lg text-gray-400 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5  lg:mx-0 mb-4 text-left">
                educatorOne.com features K-12 teacher job opportunities in top
                CBSE schools, ICSE schools, IGCSE schools, IB schools,
                Preschools, Play schools, Primary schools and State Board
                Schools. Teachers can apply online for various vacant positions
                in K-12 schools.
              </p>
              <p className="mt-3 text-lg text-gray-400 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5  lg:mx-0 mb-4 text-left">
                Schools looking to hire teachers can post online teaching job
                vacancies for free, hire from more than 1 Lakh registered
                teachers, easily manage teacher recruitment
              </p>
            </div>
          </motion.h3>
          <div className="lg:inset-y-0 lg:right-0 lg:w-1/2 my-4 z-[5]">
            <motion.h3
              className="text-3xl  text-left mb-4"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <img
                className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                src="https://www.schoolserv.in/static/images/header_img/Hire_Teacher.jpg"
                alt=""
              />
            </motion.h3>
          </div>
        </div>
      </section>
      <motion.h3
        className="  text-left"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        <div className="mx-auto max-w-7xl py-10 px-12">
          <h3 className="text-3xl font-bold mb-5 text-gray-900 ">
            Benefits to Teachers
          </h3>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-16">
            <div className="text-center">
              <div className="relative w-32 h-32 mx-auto mb-4">
                <div className="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/exclusive-1.png"
                    alt="Exclusive"
                  />
                </div>
                <div className="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-400 rounded-full"></div>
              </div>
              <h3 className="text-3xl font-bold mb-5 text-gray-900">
                Exclusive
              </h3>
              <p className="mt-2  text-lg leading-8 px-8 text-left ">
                Begin by envisioning the new look and features for your website.
              </p>
            </div>
            <div className="text-center">
              <div className="relative w-32 h-32 mx-auto mb-4">
                <div className="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/get_connected-1.png"
                    alt="Redesign"
                  />
                </div>
                <div className="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-400 rounded-full"></div>
              </div>
              <h3 className="text-3xl font-bold mb-5 text-gray-900">
              Redesign
              </h3>
              <p className="mt-2  text-lg leading-8 px-8 text-left ">
              We connect you with the best educator opportunities
              </p>
            </div>
            <div className="text-center">
              <div className="relative w-32 h-32 mx-auto mb-4">
                <div className="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/explore_schools-1.png"
                    alt="Relaunch"
                  />
                </div>
                <div className="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-400 rounded-full"></div>
              </div>
              <h3 className="text-3xl font-bold mb-5 text-gray-900">
              Explore Schools
              </h3>
              <p className="mt-2  text-lg leading-8 px-8 text-left ">
              Find the right school and best role for you
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-12">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="relative w-32 h-32 mx-auto mb-4">
                <div className="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/multimedia_profiles-1.png"
                    alt="Exclusive"
                  />
                </div>
                <div className="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-400 rounded-full"></div>
              </div>
              <h3 className="text-3xl font-bold mb-5 text-gray-900">
              Multimedia Profiles
              </h3>
              <p className="mt-2  text-lg leading-8 px-8 text-left ">
              Profiles that increases your chances of hireability
              </p>
            </div>
            <div className="text-center">
              <div className="relative w-32 h-32 mx-auto mb-4">
                <div className="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/get_references-1.png"
                    alt="Redesign"
                  />
                </div>
                <div className="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-400 rounded-full"></div>
              </div>
              <h3 className="text-3xl font-bold mb-5 text-gray-900">
              Get References
              </h3>
              <p className="mt-2  text-lg leading-8 px-8 text-left ">
              Get confidential references
              </p>
            </div>
            <div className="text-center">
              <div className="relative w-32 h-32 mx-auto mb-4">
                <div className="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                  <img
                    src="https://www.schoolserv.in/static/images/header_img/privacy-1.png"
                    alt="Relaunch"
                  />
                </div>
                <div className="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-400 rounded-full"></div>
              </div>
              <h3 className="text-3xl font-bold mb-5 text-gray-900">
              Privacy
              </h3>
              <p className="mt-2  text-lg leading-8 px-8 text-left ">
              Block specific schools from viewing your profile
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-12 flex mb-4 ">
          <div className="container relative flex flex-col justify-between h-full max-w-6xl px-12 mx-auto xl:px-0 mt-5 py-20">
            <div className="w-full">
              <h3 className="text-3xl font-bold mb-5 text-gray-900 ">
                Benefits to  Schools
              </h3>
              <div className="flex flex-col w-full mb-10 sm:flex-row"></div>
              <div className="flex flex-col w-full mb-5 sm:flex-row items-center">
                <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
                  <div className="relative h-full ml-0 mr-0 sm:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-blue-400 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-blue-400 rounded-lg">
                      <div className="flex items-center -mt-1">
                        <img src="https://www.schoolserv.in/static/images/header_img/any_time_hire-1.png" />
                        <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                          Any Time Hire
                        </h3>
                      </div>

                      <p className="text-lg mb-2 text-gray-600">
                        To apply, simply email your resume to our hiring team.
                        We look forward to hearing from you!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
                  <div className="relative h-full ml-0 mr-0 sm:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-blue-400 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-blue-400 rounded-lg">
                      <div className="flex items-center -mt-1">
                        <img src="https://www.schoolserv.in/static/images/header_img/Untitled-1-1.png" />
                        <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                          Targeted Reach
                        </h3>
                      </div>

                      <p className="text-lg mb-2 text-gray-600">
                      Targeted recruiting via K-12 School Pages, digital, and social media channels.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2">
                  <div className="relative h-full ml-0 md:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-blue-400 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-blue-400 rounded-lg">
                      <div className="flex items-center -mt-1">
                        <img src="https://www.schoolserv.in/static/images/header_img/easily_manage-1.png" />
                        <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                          Easily Manage
                        </h3>
                      </div>

                      <p className="text-lg mb-2 text-gray-600">
                        Access candidate portfolio, easily manage end to end
                        hiring process across branches
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.h3>
    </div>
  );
}
