import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
function LeadershipTeams() {
    const location = useLocation();

    useEffect(() => {
      if (location.hash === "#leadership-teams") {
        const element = document.getElementById("leadership-teams");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [location]);
  return (
    <div
      id="leadership-teams"
      className="container mx-auto px-4 lg:px-40 py-10"
    >
      <div id="leadership-teams">
        <div className="">
          <div className="text-center md:text-left">
            <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold mb-5 text-[#86c753]">
              <span className="text-"> Leadership</span>
            </h1>
            {/* <div className="flex justify-center sm:justify-start">
                <hr className="border-b-2 border-indigo-500 w-10 md:w-20 lg:w-40" />
              </div> */}
          </div>

          <p className="text-left w-full md:w-auto py-5">
            Team School Serv is passionate about increasing educational
            outcomes, achieving better results for our partner schools – results
            that go beyond financial and are social, holistic and uniquely
            tailored.
          </p>

          <div className="text-center md:text-left">
            <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold mb-5 text-[#2d7ab9]">
              <span className="text-"> International School Consultants</span>
            </h1>
            {/* <div className="flex justify-center sm:justify-start">
                <hr className="border-b-2 border-indigo-500 w-10 md:w-20 lg:w-40" />
              </div> */}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-3">
            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Vinod_Kakumanu.png"
                  alt="Image 1"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Vinod Kakumanu</h3>
              <p>Lead Consultant & Founder</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Subhashini.png"
                  alt="Image 2"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Subhashini</h3>
              <p>Chief - Administration</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Lekha.png"
                  alt="Image 3"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Lekha</h3>
              <p>Chief - Operations</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Greg_Parry.png"
                  alt="Image 4"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Greg Parry</h3>
              <p>Senior Consultant (GSE CEO)</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Shanna_Parry.png"
                  alt="Image 5"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Shanna Parry</h3>
              <p>Senior Consultant (GSE Managing Partner)</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Dr_Stewart_Saint_David.png"
                  alt="Image 6"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Dr.StewartSaint David</h3>
              <p>
                Specialist Consultant Middle School, Secondary School,
                Curriculum, Marketing and Recruitment
              </p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Brent_Werry.png"
                  alt="Image 7"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">BrentWerry</h3>
              <p>
                Specialist Consultant Early Childhood, Middle School, Facilities
                and Design
              </p>
            </div>
          </div>
          <div className="text-center md:text-left">
            <div className="py-5">
              <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold mb-5 text-[#86c753]">
                <span className="text-"> Strategic Advisory Board</span>
              </h1>
              {/* <div className="flex justify-center sm:justify-start">
                  <hr className="border-b-2 border-indigo-500 w-10 md:w-20 lg:w-40" />
                </div> */}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-3">
            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Bimal_Nair.png"
                  alt="Image 1"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Bimal Nair</h3>
              <p>Expert Advisor – Branding & Marketing</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Gary_Smejkal.png"
                  alt="Image 2"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Gary Smejkal</h3>
              <p>University of New Hampshire, Affiliate Assistant Professor</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Hilla_Rudanko.png"
                  alt="Image 3"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Hilla Rudanko</h3>
              <p>
                CEO, Partner, Architects Rudanko + Kankkunen Ltd. Helsinki Area,
                Finland
              </p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Dr_Jeane_Bosco_Tagne.png"
                  alt="Image 4"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Dr Jeane-Bosco Tagne</h3>
              <p>Assistant Professor of Molecular Medicine</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Dr_Srikanth_Kakumanu.png"
                  alt="Image 5"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Dr Srikanth Kakumanu.</h3>
              <p>Expert Advisor - STEM Curriculum</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Kunal_Sinha.png"
                  alt="Image 6"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Kunal Sinha</h3>
              <p>Glowroad.com, Founder & CEO</p>
            </div>
            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/N_Raja_Sujith.png"
                  alt="Image 7"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">N.Raja-Sujith</h3>
              <p>Partner and Head of South India Majumdar</p>
            </div>

            <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <div className="flex justify-center items-center mb-3">
                <img
                  src="https://www.schoolserv.in/media/testinomial/Tulasi_Babu_G.png"
                  alt="Image 6"
                  className="h-40 rounded-full"
                />
              </div>
              <h3 className="font-bold">Tulasi Babu G</h3>
              <p>Expert Advisor - Academics</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadershipTeams;
