import React, { useEffect } from "react";
import { motion } from "framer-motion";
import blog from "./Assets/Images/blog.png";
export default function NewProject() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div>
        <div className="relative py-12">
          <div className="relative h-96 md:h-auto">
            <img
              src="https://www.satyamevgroups.com/wp-content/uploads/2022/08/our-projects.webp"
              className="w-full h-full object-cover"
              alt="Background"
            />
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black "></div>
          </div>

          <div className="absolute top-3/4 bottom-4/6 inset-x-0 transform translate-y-2/2 flex justify-center items-center">
            <img
              src={blog}
              className="max-w-full h-auto object-cover "
              alt="Contact Us Banner"
            />
          </div>
        </div>
        <motion.div
          className="container mx-auto px-12 max-w-screen-xl flex flex-col items-center justify-center mb-10 z-[5]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="text-left z-[5]">
            <motion.h2
              className="text-3xl font-extrabold text-left mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <h2 className="text-3xl font-extrabold text-left mb-4">
                Projects
              </h2>
            </motion.h2>
            <motion.p
              className="text-lg z-[5]"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
            >
              <p className="text-lg">
                Explore the diverse array of projects we undertake and unlock a
                realm of possibilities. Our customized solutions are
                meticulously crafted to revolutionize your endeavors and empower
                you to achieve extraordinary results.
              </p>{" "}
            </motion.p>
          </div>

          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-16 z-[5]">
              <motion.div
                className="z-[5]"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div className="z-[5]">
                  <img
                    src="https://img.freepik.com/free-photo/city-committed-education-collage-concept_23-2150062212.jpg?size=626&ext=jpg&ga=GA1.1.629983002.1709806369&semt=ais"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <p className="text-lg">
                    This initial phase involves thorough research and analysis
                    to understand the education sector comprehensively. It
                    includes studying market trends, educational policies,
                    demographic data, and potential competitors. Understanding
                    the sector helps in identifying opportunities and
                    challenges, which are crucial for making informed decisions
                    throughout the project.
                  </p>
                </div>
              </motion.div>
              <div className="max-md:order-1">
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                >
                  <p className="text-lg">
                    In this phase, the school’s vision, mission, and educational
                    philosophy are clearly defined. This involves brainstorming
                    sessions with stakeholders, including founders, educators,
                    parents, and community members, to articulate the unique
                    identity and offerings of the school. Clarifying the
                    school’s vision lays the foundation for its identity,
                    curriculum, teaching methodologies, and extracurricular
                    activities.
                  </p>
                </motion.div>
              </div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <img
                    src="https://rutherfordschool.ca/wp-content/uploads/2021/01/RPS-mission-vision-values-2.jpg"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
            </div>

            <div className="mt-16">
              <div className="grid md:grid-cols-2 items-center gap-16">
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                >
                  <div>
                    <img
                      src="https://img.freepik.com/premium-photo/stack-books-with-laptop-wooden-table_488220-73738.jpg?size=626&ext=jpg&ga=GA1.1.629983002.1709806369&semt=ais"
                      className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                    />
                  </div>
                </motion.div>
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 1 }}
                >
                  <div>
                    <p className="text-lg">
                      Depending on the project’s scope and requirements,
                      partnerships may be sought with various entities such as
                      educational consultants, architects, construction firms,
                      and legal advisors. This phase involves identifying
                      potential partners, evaluating their expertise and
                      compatibility with the project’s goals, and negotiating
                      terms of engagement. Partnering with the right
                      stakeholders can enhance the project’s success by
                      leveraging their specialized knowledge and resources.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 1 }}
                >
                  <div className="max-md:order-1">
                    <p className="text-lg">
                      Before proceeding with the implementation phase, a
                      detailed feasibility study and business plan are essential
                      to assess the project’s viability and sustainability. This
                      involves conducting market surveys, financial analysis,
                      and risk assessments to evaluate the project’s
                      feasibility. The business plan outlines the school’s
                      organizational structure, budget, revenue streams,
                      marketing strategies, and risk mitigation measures. A
                      well-defined business plan provides a roadmap for
                      achieving the project’s objectives and securing necessary
                      funding and resources.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  className=""
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 1 }}
                >
                  <div>
                    <img
                      src="https://www.implementation.com/wp-content/uploads/2017/06/what-is-implementation.jpg"
                      className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                    />
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
          <div className="mt-16">
            <div className="grid md:grid-cols-2 items-center gap-16">
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <div>
                  <img
                    src="https://www.celoxis.com/cassets/img/pmc/project-management.png"
                    className="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
                  />
                </div>
              </motion.div>
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div>
                  <p className="text-lg">
                    The final phase involves executing the project plan,
                    bringing the school’s vision to life. This includes securing
                    land or facilities, obtaining regulatory approvals,
                    recruiting staff, developing curriculum and educational
                    materials, designing infrastructure, and setting up
                    administrative systems. Effective project management is
                    crucial during this phase to ensure timely execution, cost
                    control, quality assurance, and stakeholder coordination.
                    Regular monitoring and evaluation help to track progress,
                    address challenges, and make necessary adjustments to ensure
                    the successful establishment and operation of the school.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
