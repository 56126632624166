import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

export default function Schoolforte() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        setIsVisible(top < window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const features = [
    {
      title: "Ready to use and Customizable Forms",
      description:
        "Customizable forms streamline inquiries, applications, admissions, and fees.",
      image:
        "https://www.schoolserv.in/static/images/Ready_to_Use_and_Customizable_Forms.png",
      alt: "Ready to Use and Customizable Forms",
    },
    {
      title: "Configurable Workflow Engine",
      description:
        "Automate various functions using the workflow engine, reducing errors and increasing productivity.",
      image:
        "https://www.schoolserv.in/static/images/Configurable%20Workflow%20Engine-01.png",
      alt: "Configurable Workflow Engine",
    },
    {
      title: "Uninterrupted Communication",
      description:
        "Establish and maintain a smooth communication channel with parents via email.",
      image:
        "https://www.schoolserv.in/static/images/Uninterrupted%20Communication-01.png",
      alt: "Uninterrupted Communication",
    },
    {
      title: "Customizable Templates",
      description:
        "Ready-to-use customizable templates for communication and circulars.",
      image:
        "https://www.schoolserv.in/static/images/Customizable%20Templates-01.png",
      alt: "Customizable Templates",
    },
    {
      title: "Data Security",
      description:
        "Your data is encrypted, protected from unauthorized access, modification, or deletion.",
      image: "https://www.schoolserv.in/static/images/Data%20Security-01.png",
      alt: "Data Security",
    },
    {
      title: "Role Based Access",
      description:
        "Customize access to features and content based on roles tailored to your school's requirements",
      image:
        "https://www.schoolserv.in/static/images/Role%20Based%20Access-01.png",
      alt: "Role Based Access",
    },
    {
      title: "Reports and Dashboard",
      description:
        "Analytical reports and dashboards to assist you sustain operations and grow revenue.",
      image: "https://www.schoolserv.in/static/images/Reports-&-Dashboard.png",
      alt: "Reports and Dashboard",
    },
    {
      title: "Unlimited Users",
      description:
        "Gear to accommodate all users in your school while ensuring data integrity.",
      image: "https://www.schoolserv.in/static/images/Unlimited-Users.png",
      alt: "Unlimited Users",
    },
    {
      title: "Unlimited Students",
      description:
        "You only pay for usage by the school, not by user or student.",
      image: "https://www.schoolserv.in/static/images/Unlimited-Students.png",
      alt: "Unlimited Students",
    },
    {
      title: "Email and phone support",
      description: "Easily accessible support through phone and email.",
      image:
        "https://www.schoolserv.in/static/images/Email-and-Phone-support.png",
      alt: "Email and phone support",
    },
    {
      title: "Import data",
      description: "Import your existing data from excel sheets.",
      image: "https://www.schoolserv.in/static/images/Import-Data.png",
      alt: "Import data",
    },
  ];

  return (
    <div>
      <div className="container mx-auto px-12 max-w-screen-xl flex flex-col items-center justify-center py-32">
        <div ref={ref} className="max-w-screen-xl mx-auto">
          <h3 className="text-3xl lg:text-3xl text-left mb-4 lg:mb-6">
            Step up your School Management with Schoolforte
          </h3>
          <p className="text-lg  text-left mb-8 lg:mb-12">
            Cloud-based school management software | school ERP which simplifies
            the admission cycle from inquiry to enrollment and aids fee
            management
          </p>
          <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between">
            <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={
                  isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
                }
                transition={{ duration: 1 }}
              >
                <div className="overflow-hidden rounded-lg">
                  <motion.img
                    src="https://www.schoolserv.in/static/images/why-schoolforte.png"
                    alt="Vision Illustration"
                    className="w-full lg:h-auto object-cover transition-transform duration-300 transform hover:scale-105 py-5"
                  />
                </div>
              </motion.div>
            </div>
            <div className="w-full lg:w-1/2">
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={
                  isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }
                }
                transition={{ duration: 1 }}
              >
                <div className="text-center lg:text-left">
                  <h1 className="text-3xl lg:text-3xl font-bold mb-4">
                    <img src="https://www.schoolserv.in/static/images/logo.png" />
                  </h1>
                  <p className="text-lg  leading-6 text-gray-600 text-justify mb-4">
                    Schoolforte derives its features from the experience of
                    India’s leading school management services organization and
                    from the experience of consistently growing network of
                    schools. The long-term issues as well as daily cares of
                    school management have been considered while developing this
                    school management software. Unlike other similar systems, it
                    has the backdrop of a well-entrenched school support
                    organization and its decades of learning.
                  </p>
                  <p className="text-lg leading-6 text-gray-600 text-justify mb-4">
                    Schoolforte empowers the school management by sequencing the
                    data and facilitating its retrieval and interpretation to
                    redesign branding and marketing strategy and revisit
                    policies thereby getting better placed for increasing
                    admissions. Its features guide you in acquiring relevant
                    information and in using it for the growth of the
                    institution.
                  </p>
                  <p className="text-lg leading-6 text-gray-600 text-justify mb-4">
                    Features of schoolforte will render school management easier
                    manifold and bring the school in the league of most
                    efficiently managed school within days of its deployment. It
                    does not exact unreasonable sum or resources neither does it
                    require exceptional computer savvy, just a little initiative
                    and a will to excel.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <img
            src="https://www.schoolserv.in/static/images/Features_Banner_01.png"
            className="w-full"
          />
        </div>

        <div>
          <section id="new-features" className="bg-white">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className="flex flex-col justify-between p-4 rounded-lg items-center"
                  >
                    <div className="w-16 h-16 flex justify-center items-center mb-4">
                      <img
                        src={feature.image}
                        alt={feature.alt}
                        className="max-w-full max-h-full object-contain image-filter"
                      />
                    </div>

                    <div className="text-center">
                      <h3 className="text-lg font-bold text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-lg text-gray-600">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
        <div>
          <img
            src="https://www.schoolserv.in/media/banner_image/Benifits_Banner_01.png"
            className="w-full mb-20"
          />
          <div className="text-center mb-4">
            {" "}
            <button className="border p-3 m-3 hover:bg-[#6ebc3e] rounded text-black hover:text-white bg-white">
              Get Started Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
