import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import blog from "./Assets/Images/blog.png";
const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      id: 1,
      image:
        "https://www.shrieducare.com/assets/images/banner_images/banner1.jpg",
      title: "Change the Face Of Education",
      description: `At Insight Eduzone, we empower students for 21st-century leadership through transformative education`,
    },
    {
      id: 2,
      image:
        "https://www.shrieducare.com/assets/images/banner_images/banner3.jpg",
      title: "Why Insight Eduzone",
      description: `We tap into prestigious academic experience and maintain enduring partnerships through long-term commitments.`,
    },

    {
      id: 3,
      image:
        "https://www.shrieducare.com/assets/images/banner_images/banner4.jpg",
      title: "Our Mission",
      description:
        "Leading center for top-tier education and customer satisfaction, fostering value for all.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(
        currentSlide === slides.length - 1 ? 0 : currentSlide + 1
      );
    }, 2000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, [currentSlide, slides.length]);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="py-0 relative">
      <div className="relative h-96 md:h-auto">
        <img
          src={slides[currentSlide].image}
          className="w-full h-full object-cover"
          alt="Background"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black "></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white flex flex-col text-start z-10">
          <div>
            <h1 className="text-3xl font-bold mb-4">
              {slides[currentSlide].title}
            </h1>
          </div>
          <div>
            <p className="text-md bg-gradient-to-r from-black">
              {slides[currentSlide].description}
            </p>
          </div>
        </div>
        <div className="absolute inset-y-1/2 left-0 transform -translate-y-1/2 flex justify-start items-center z-10">
          <button
            onClick={prevSlide}
            className="px-2 py-2 bg-black text-white rounded-md ml-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        <div className="absolute inset-y-1/2 right-0 transform -translate-y-1/2 flex justify-end items-center z-10">
          <button
            onClick={nextSlide}
            className="px-2 py-2 bg-black text-white rounded-md mr-2"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <div className="absolute inset-x-0 top-3/4 md:bottom-4/6 transform md:translate-y-2/2 flex justify-center items-center">
          <img
            src={blog}
            className="max-w-full h-auto object-cover py-10"
            alt="Contact Us Banner"
          />
        </div>
      </div>

      <section className="bg-white">
        <div className="z-10 container mx-auto px-4 max-w-screen-xl flex flex-col lg:flex-row items-center justify-center lg:justify-between gap-8 lg:gap-0">
          <motion.div
            className="w-full lg:w-1/2 px-6 lg:px-8 font-light z-10"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <div className="text-center lg:text-left z-10">
              <h3 className="text-3xl font-semibold leading-8 text-gray-900 mb-4 z-10">
                Transforming K-12 Education
              </h3>
              <p className="mt-6 text-md leading-8 text-gray-600 z-10">
                Driven by our unwavering dedication to shaping tomorrow's
                leaders, we pioneer school project consultancy, crafting unique
                educational experiences for the 21st century. Through a blend of
                global insights and technology, we design sustainable schools
                that excel within their communities.
              </p>
              <p className="mt-6 text-md leading-8 text-gray-600 z-10">
                Our commitment extends beyond establishment; we foster
                environments that inspire creativity and leadership. By
                launching independent school brands, we redefine learning,
                ensuring each institution embodies innovation and inclusivity.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="w-full lg:w-1/2 px-6 lg:px-8 mt-8 lg:mt-0 z-10"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.div
              className="transform transition duration-2000 hover:scale-105"
              whileHover={{ scale: 1.05 }}
            >
              <img
                src="https://t3.ftcdn.net/jpg/03/15/84/80/360_F_315848034_kptZtMElEgMdftxIyqJqLGEYsGb76Glg.jpg"
                // src="https://www.shrieducare.com/assets/images/legecyBg.png"
                alt="Description of your image"
                className="w-full h-auto object-cover bg-white"
              />
            </motion.div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Home;
