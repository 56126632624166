import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header";
import { Route, Routes, useLocation } from "react-router-dom";

import Footer from "./Components/Footer";
import Main from "./Components/Main";
import ContactUs from "./Components/ContactUs";
import Innovation from "./Components/Innovation";
import KnowUs from "./Components/KnowUs";
import LeadershipTeams from "./Components/LeadershipTeams";
import Mission from "./Components/Mission";
import Values from "./Components/Values";
import ServOffer from "./Components/ServOffer";
import Consultancy from "./Components/Consultancy";
import NewProject from "./Components/NewProject";
import SchoolSetUp from "./Components/SchoolSetUp";
import HireTeachers from "./Components/HireTeachers";
import Schoolforte from "./Components/Schoolforte";

function App() {
  const location = useLocation();

  return (
    <div>
      <Header />

      <Routes>
        <Route path="/" element={<Main />} />

        <Route path="/service" element={<ServOffer />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/innovation" element={<Innovation />} />
        <Route path="/consult" element={<Consultancy />} />
        <Route path="/newproject" element={< NewProject />} />
        <Route path="/sclsetup" element={< SchoolSetUp />} />
        <Route path="/hire" element={< HireTeachers />} />
        <Route path="/sclforte" element={<Schoolforte />} />
        <Route
          path="/knowus"
          element={
            <KnowUs>
              {location.pathname === "/knowus" && <LeadershipTeams />}
              <Mission />
              <Values />
            </KnowUs>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
