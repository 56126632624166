import React, { useEffect, useState } from "react";
import blog from "./Assets/Images/blog.png";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    twitter: "",
    address: "",
    number: "",
    email: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(formData);
    // Add form submission logic here
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="mb-10">
      <div className="relative py-20">
        <div className="relative h-96 md:h-auto">
          <img
            src="https://www.shrieducare.com/assets/images/banner_images/contact-us.jpg"
            className="w-full h-full object-cover"
            alt="Background"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black "></div>
        </div>

        <div className="absolute top-3/4 bottom-4/6 inset-x-0 transform translate-y-2/2 flex justify-center items-center">
          <img
            src={blog}
            className="max-w-full h-auto object-cover "
            alt="Contact Us Banner"
          />
        </div>
      </div>

      <div className="container mx-auto px-5 lg:px-32 ">
        <div className="flex flex-col lg:flex-row justify-center ">
          <div className="lg:w-1/2 lg:px-10 z-[5]">
            <div className="mt-4">
              <h3 className="text-3xl font-semibold leading-6 text-gray-900 ">
                Insight Eduzone
              </h3>
              <motion.div
                className=""
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <ul className="mt-4">
                  <li className="py-3 flex items-center">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="mr-2 z-[5]"
                    />
                    <span className="text-md leading-8 text-gray-600 z-[5]">
                      Address: No.5, Dhanalakshmi Nagar Main Road, Selaiyur,
                      Chennai 600073
                    </span>
                  </li>
                  <li className="py-3 flex items-center">
                    <FontAwesomeIcon icon={faWhatsapp} className="mr-2 z-[5]" />
                    <span className="text-md leading-8 text-gray-600 z-[5]">
                      Whatsapp: 7200267543
                    </span>
                  </li>
                  <li className="py-3 flex items-center ">
                    <FontAwesomeIcon icon={faPhoneAlt} className="mr-2 z-[5]" />
                    <span className="text-md leading-8 text-gray-600 z-[5]">
                      Landline: 04443192610
                    </span>
                  </li>
                  <li className="py-3 flex items-center">
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2 " />
                    <span className="text-md leading-8 text-gray-600">
                      Email: insighteduzone@gmail.com
                    </span>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>

          <div className="lg:w-1/2 z-[5]">
            <motion.div
              className=""
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="bg-[#f4f4f4] flex items-center justify-center py-4 rounded-lg">
                <form
                  onSubmit={handleSubmit}
                  className="w-full px-5 flex flex-col justify-center text-md leading-8 text-gray-600"
                >
                  <input
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border-b-2 bg-[#f4f4f4] py-2 placeholder-gray-700 focus:outline-[#86c753] mb-5 text-md"
                  />
                  <input
                    name="twitter"
                    type="text"
                    placeholder="Occupation"
                    value={formData.twitter}
                    onChange={handleChange}
                    className="border-b-2 bg-[#f4f4f4] py-2 placeholder-gray-700 focus:outline-[#86c753] mb-5 text-md"
                  />
                  <textarea
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                    className="border-b-2 bg-[#f4f4f4] py-2 placeholder-gray-700 focus:outline-[#86c753] mb-5 text-md"
                  />
                  <input
                    name="number"
                    type="number"
                    placeholder="Phone Number"
                    value={formData.number}
                    onChange={handleChange}
                    className="border-b-2 bg-[#f4f4f4] py-2 placeholder-gray-700 focus:outline-[#86c753] mb-5 text-md"
                  />
                  <input
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    value={formData.email}
                    onChange={handleChange}
                    className="border-b-2 bg-[#f4f4f4] py-2 placeholder-gray-700 focus:outline-[#86c753] mb-5 text-md"
                  />
                  <button
                    type="submit"
                    className="pl-3 pr-3 p-3 border border-[#86c753] text-black hover:bg-[#86c753] hover:text-white text-sm rounded-lg"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
