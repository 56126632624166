import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import {
  FaStar,
  FaFlag,
  FaHandshake,
  FaChalkboardTeacher,
  FaBook,
  FaSuitcase,
  FaMapPin,
  FaShoppingCart,
  FaGraduationCap,
  FaSchool,
} from "react-icons/fa";

const Influencing: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        setIsVisible(top < window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const influencingData = [
    {
      number: "14+",
      description: "13+ years in K-12 education expertise.",
      icon: <FaStar />,
      iconColor: "#d3d3d3",
      hoverColor: "#73BD35",
    },
    {
      number: "35+",
      description: "Serving 10000+  Private Schools",
      icon: <FaSchool />,
      iconColor: "#d3d3d3",
      hoverColor: "#73BD35",
    },
    {
      number: "10+",
      description: "Planned and Established 100+ Schools",
      icon: <FaFlag />,
      iconColor: "#d3d3d3",
      hoverColor: "#73BD35",
    },
    {
      number: "210+",
      description: "Helped transform 35+ Schools",
      icon: <FaHandshake />,
      iconColor: "#d3d3d3",
      hoverColor: "#73BD35",
    },

    {
      number: "30,000+",
      description: "Enriching learning of 300000+ children",
      icon: <FaBook />,
      iconColor: "#d3d3d3",
      hoverColor: "#73BD35",
    },
    {
      number: "1000+",
      description: "Helped 100,000+ teachers find fitting jobs.",
      icon: <FaSuitcase />,
      iconColor: "#d3d3d3",
      hoverColor: "#73BD35",
    },
  ];

  return (
    <div
      ref={ref}
      className="container mx-auto px-4 max-w-screen-xl flex flex-col items-center justify-center py-20"
    >
      <div className="text-center max-w mx-auto">
        <h3 className="text-3xl lg:text-left font-semibold leading-8 text-gray-900">
          Influencing Every Sphere of K-12 School Endeavor
        </h3>
      </div>
      <div className="my-8 text-md lg:text-md leading-8 text-gray-600 text-justify">
        <p className="mt-2 leading-8 px-8">
          Our resolve for quality and comprehensiveness has not only enabled us
          to establish world-class schools, it also drove us to create an
          all-encompassing array of online services and tools for the K-12
          school sector.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 justify-center max-w-screen-lg">
        {influencingData.map((data, index) => (
          <motion.div
            key={index}
            className={`border p-5 text-center flex items-center justify-center transition-transform transform col-span-1 row-span-1 min-w-0 ${
              index < 3 ? "first-three" : ""
            }`}
            initial={{ opacity: 0, y: -50 }}
            animate={{
              opacity: isVisible ? 1 : 0,
              y: isVisible ? 0 : -50,
              transition: { duration: 0.7, delay: index * 0.2 },
            }}
            whileHover={{
              scale: 1.1,
              borderColor: "#73BD35",
            }}
            onHoverStart={() => setHoveredIndex(index)}
            onHoverEnd={() => setHoveredIndex(null)}
          >
            <div className="flex flex-row items-center mr-10">
              <div className="mb-2 mr-10">
                {React.cloneElement(data.icon, {
                  size: "3rem",
                  color:
                    hoveredIndex === index ? data.hoverColor : data.iconColor,
                })}
              </div>
              <div className="text-left">
                <div className="text-3xl font-bold">{data.number}</div>
                <div className="text-md leading-8 text-gray-600 text-justify">
                  {data.description}
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Influencing;
