import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
const Footer: React.FC = () => {
  const [formData, setFormData] = useState({
    ftname: "",
    ftphone: "",
    ftemail: "",
    ftcity: "",
    ftparea: "",
    area_cost: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <footer className="bg-[#383838] text-white py-12">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 lg:max-w-6xl md:max-w-4xl sm:max-w-full mx-4 md:mx-auto">
          <div className="mb-8 md:mb-0">
            <h5 className="mb-4 font-bold uppercase text-3xl">GET CONNECTED</h5>
            <ul className="list-style-type: none; padding: 0;">
              <li className="place py-3 flex items-center">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="mr-2 text-blue-500"
                />
                <span>
                  Address: No.5, Dhanalakshmi Nagar Main Road, Selaiyur, Chennai
                  600073
                </span>
              </li>
              <li className="tel py-3 flex items-center">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="mr-2 text-blue-500"
                />
                <span>Whatsapp number you can use is 7200267543.</span>
              </li>
              <hr />
              <li className="tel py-3 flex items-center">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="mr-2 text-blue-500"
                />
                <span>04443192610 landline</span>
              </li>
              <li className="tel py-3 flex items-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mr-2 text-blue-500"
                />
                <span>insighteduzone@gmail.com</span>
              </li>
            </ul>
          </div>

          <div className="">
            <h5 className="mb-4 font-bold uppercase text-3xl">USEFUL LINKS</h5>
            <ul className="list-none">
              <li className="mb-2">
                <Link to="/" className="">
                  <span className="dropdown-arrow text-md text-blue-500">
                    &#9654;
                  </span>{" "}
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/" className="">
                  <span className="dropdown-arrow text-md text-blue-500">
                    &#9654;
                  </span>{" "}
                  About Us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/contactus" className="">
                  <span className="dropdown-arrow text-md text-blue-500">
                    &#9654;
                  </span>{" "}
                  Contact Us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="#" className="">
                  <span className="dropdown-arrow text-md text-blue-500">
                    &#9654;
                  </span>{" "}
                  Clients
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-8 md:mb-0">
            <form
              id="ftrform"
              className="max-w-lg mx-auto"
              onSubmit={handleSubmit}
            >
              <input
                type="text"
                name="ftname"
                value={formData.ftname}
                onChange={handleChange}
                className="form-input w-full mb-4 px-4 py-2 rounded outline-green-200 text-black"
                required
                placeholder="Name"
              />
              <div className="mb-4 md:flex md:gap-4">
                <input
                  type="tel"
                  name="ftphone"
                  value={formData.ftphone}
                  onChange={handleChange}
                  className="form-input w-full md:w-1/2 mb-4 md:mb-0 px-4 py-2 rounded outline-green-200 text-black"
                  required
                  placeholder="Phone Number"
                />
                <input
                  type="email"
                  name="ftemail"
                  value={formData.ftemail}
                  onChange={handleChange}
                  className="form-input w-full md:w-1/2 px-4 py-2 rounded outline-green-200 text-black"
                  required
                  placeholder="Email"
                />
              </div>
              <div className="mb-4 md:flex md:gap-4">
                <input
                  type="text"
                  name="ftcity"
                  value={formData.ftcity}
                  onChange={handleChange}
                  className="form-input w-full md:w-1/2 mb-4 md:mb-0 px-4 py-2 rounded outline-green-200 text-black"
                  required
                  placeholder="City"
                />
                <input
                  type="text"
                  name="ftparea"
                  value={formData.ftparea}
                  onChange={handleChange}
                  className="form-input w-full md:w-1/2 px-4 py-2 rounded outline-green-200 text-black"
                  required
                  placeholder="Plot Size"
                />
              </div>
              <div className="mb-4">
                <select
                  name="area_cost"
                  value={formData.area_cost}
                  onChange={handleChange}
                  className="form-select w-full px-4 py-2 rounded text-black outline-green-200 "
                >
                  <option value="0">
                    Please share your investment Potential
                  </option>
                  <option value="20 L - 50 L (Preschool)">
                    20 L - 50 L (Preschool)
                  </option>
                  <option value="50 L - 1 Cr (Preschool)">
                    50 L - 1 Cr (Preschool)
                  </option>
                  <option value="1 Cr - 15 Cr (K-12 school)">
                    1 Cr - 15 Cr (K - 12 School)
                  </option>
                  <option value="15 Cr - 30 Cr (K-12 School)">
                    15 Cr - 30 Cr (K - 12 School)
                  </option>
                </select>
              </div>
              <div className="mb-4 text-center">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 text-justify gap-8 md:gap-16 lg:max-w-6xl md:max-w-4xl sm:max-w-full mx-4 md:mx-auto">
        <h1 className="font-bold text-md">VISION & MISSION OF EduZone</h1>{" "}
        <div
          className="marquee-content"
          style={{
            overflow: "hidden",
            whiteSpace: "wrap",
            paddingTop: "50px",
            height: "50px",
          }}
        >
          <div
            className="marquee-inner"
            style={{ animation: "marquee 15s linear infinite" }}
          >
            <p className="font-bold">What is IEZ:</p>
            <p>
              Insight Eduzone, led by Mr. Om Sivaramakrishnan T, is a private
              limited company operating in the field of education consultancy
              services. With its headquarters in Krishna, Andhra Pradesh, India,
              the company specializes in providing comprehensive support to
              schools, educational institutions, and teachers.
            </p>

            <p>
              Established on July 29, 2021, Insight Eduzone aims to assist
              educational institutions in various aspects of educational
              development and management. This includes services such as board
              affiliations, curriculum development, procurement, STEM program
              development, library management, experiential learning
              initiatives, career development, and assistance with international
              university admissions.
            </p>
            <p>
              Under the leadership of Mr. Om Sivaramakrishnan T, Insight Eduzone
              is committed to enhancing educational outcomes and fostering
              holistic development within partnering schools and institutions.
              With a team of experienced professionals and a dedication to
              excellence, Insight Eduzone strives to provide tailored solutions
              that meet the diverse needs of its clients in the education
              sector.
            </p>
          </div>
        </div>
      </div>
      <div className="text-center mt-8">
        <p className="text-md">
          <a href="/" className="text-white">
            &copy; 2024 Insight Eduzone . All Rights Reserved.
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
