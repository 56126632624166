
import React, { useEffect, useState, ReactNode, useRef } from "react";
import { motion } from "framer-motion";
import blog from "./Assets/Images/blog.png";
import sclbanner from "./Assets/Images/sclsbanes.png";
import { Outlet } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
interface KnowUsProps {
  children: ReactNode;
}

const KnowUs: React.FC<KnowUsProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false); 

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        setIsVisible(top < window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    AOS.init({
        offset: 200, 
        delay: 0,
        duration: 1000,
        easing: 'ease',
        once: true, 
        mirror: false, 
      });
      
  }, []);
  return (
    <div>
      <div className="mb-10">
        <div className="relative py-20">
          <div className="relative h-96 md:h-auto">
            <img
              src="https://www.shrieducare.com/assets/images/banner_images/contact-us.jpg"
              className="w-full h-full object-cover"
              alt="Background"
            />
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black "></div>
          </div>

          <div className="absolute top-3/4 bottom-4/6 inset-x-0 transform translate-y-2/2 flex justify-center items-center">
            <img
              src={blog}
              className="max-w-full h-auto object-cover "
              alt="Contact Us Banner"
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 lg:px-40">
        {/* Section: Know Us */}
        <div className="flex flex-wrap items-center mt-8 px-4">
          {/* Image */}
          <div className="w-full lg:w-1/2 z-[5]">
            <img
              src="https://shrieducare.com/assets/images/fck_filesimages/known-us-image.jpg"
              alt="Description of your image"
              className="h-auto lg:w-full md:w-4/5 sm:w-full object-cover bg-white rounded-lg shadow-lg mr-4 hover:transform hover:scale-105 transition duration-300 ease-in-out"
            />
          </div>

          {/* Description */}
          <div id="know-us-section" className="w-full lg:w-1/2 z-[5] lg:pl-8">
            <div className="text-center lg:text-left ">
              <h3 className="text-3xl font-bold mb-5 text-gray-900 ">
                <span>Know us</span>
              </h3>
              <p className="text-md leading-7 text-gray-600 text-justify z-[5]">
                Insight Eduzone Pvt Ltd, established in 2021, leads educational
                transformation under Mr. Om Sivaramakrishnan T's guidance,
                aiming to revolutionize the sector. Specializing in advisory
                services, we assist in establishing schools tailored to diverse
                needs in India and abroad, from preschool to K12, catering to
                local communities and NRIs. With vast experience, we expand
                quality school networks, raising educational standards globally,
                ensuring exceptional education for all. Join us in this
                transformative journey.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="mission-vision"
        className="container mx-auto px-4 lg:px-40 py-2"
        ref={ref}
      >
        {/* Section: Mission and Vision */}
        <div className="flex flex-wrap items-center mt-8 px-4">
          {/* Mission */}
          <div className="w-full lg:w-1/2 z-[5] lg:pr-8">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={
                isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
              }
              transition={{ duration: 1, delay: 0.5 }} // Delay added for smoother animation
              whileHover={{ scale: 1 }}
              className="transform transition duration-2000 hover:scale-105 "
            >
              <div className="text-center lg:text-left">
                <h3 className="text-3xl font-bold mb-5 text-gray-900">
                  <span>Our Mission</span>
                </h3>
                <div className="text-md leading-7 text-gray-600 text-justify">
                  <p>
                    Our mission is to provide unwavering support to clients who
                    aspire for growth and success. We prioritize empowering
                    their teams with confidence, guiding them every step of the
                    way towards achieving their goals. Through personalized
                    assistance and steadfast encouragement, we aim to ensure our
                    clients' success in their educational endeavors.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>

          <div className="w-full lg:w-1/2 z-5">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={
                isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
              }
              transition={{ duration: 1, delay: 0.5 }}
              whileHover={{ scale: 1 }}
              className="transform transition duration-2000 hover:scale-105 "
            >
              <img
                src="https://shrieducare.com/assets/images/fck_filesimages/mission.jpg"
                alt="Description of your image"
                className="h-auto w-auto lg:w-full object-cover bg-white rounded-lg shadow-lg hover:transform hover:scale-105 transition duration-300 ease-in-out"
              />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 lg:px-40">
        <div className="flex flex-wrap items-center mt-8 px-4">
          <div className="w-full lg:w-1/2 z-[5]">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={
                isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
              }
              transition={{ duration: 1 }}
              whileHover={{ scale: 1 }}
              className="transform transition duration-2000 hover:scale-105"
            >
              <img
                src="https://shrieducare.com/assets/images/fck_filesimages/our-vission.jpg"
                alt="Description of your image"
                className="h-auto lg:w-full object-cover bg-white rounded-lg shadow-lg hover:transform hover:scale-105 transition duration-300 ease-in-out"
              />
            </motion.div>
          </div>
          <div className="w-full lg:w-1/2 z-[5] lg:pl-8">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={
                isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
              }
              transition={{ duration: 1 }}
              whileHover={{ scale: 1 }}
              className="transform transition duration-2000 hover:scale-105"
            >
              <div className="text-center lg:text-left">
                <h3 className="text-3xl font-bold mb-5 text-gray-900">
                  <span>Our Vision</span>
                </h3>
                <p className="text-md leading-8 text-gray-600 text-justify">
                  Our Vision is to equip every individual with the power of
                  knowledge. Whether you're a student, a teacher, or part of the
                  management team, we're dedicated to providing unparalleled
                  support in the education industry. Our aim is to deliver the
                  best possible services to clients who seek our assistance,
                  ensuring that everyone has access to exceptional educational
                  opportunities.
                </p>
              </div>
            </motion.div>
          </div>

          <div id="Values-value">
            <div className="flex justify-center py-10 PX-10">
              <div className="items-start justify-center">
                <div className="text-justify ">
                  <h3 className="text-3xl font-bold mb-5 text-gray-900">
                    <span> Our Values</span>
                  </h3>

                  <p className="mt-6 text-md leading-8 text-gray-600 text-justify ">
                    Our core values reflect the basic principles that guide our
                    interactions with every stakeholder of the organization. At
                    Shri Educare Limited, values are not just strong words.
                    These are a collection of habits that should be reflected in
                    our day-to-day behavior.
                  </p>

                  <div className="mb-8 flex flex-col sm:flex-row items-center ">
                    <section className="max-w-screen-xl mx-auto ">
                      <div className="flex flex-col gap-6 mt-16">
                        <div className="flex flex-col md:flex-row gap-6">
                          <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden" id="section2" data-aos="fade-up"  data-aos-delay="200">
                            <img
                              src="https://shrieducare.com/assets/images/fck_filesimages/1_customer-first.jpg"
                              alt="customer"
                              className="w-full h-full object-cover aspect-video lg:aspect-square"
                            />
                          </div>
                          <div className="w-full lg:w-9/12 shadow rounded-3xl flex flex-col justify-center p-8 lg:p-14"id="section2" data-aos="fade-up"  data-aos-delay="200">
                            <h3 className="text-3xl capitalize  font-semibold">
                              Customer First
                            </h3>

                            <p className="text-lg py-5">
                              We prioritize our customers and strive to provide
                              them with the best services possible. Our
                              commitment to customer satisfaction drives all our
                              interactions.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col md:flex-row-reverse gap-6" id="section3" data-aos="fade-up"  data-aos-delay="300">
                          <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden">
                            <img
                              src="https://shrieducare.com/assets/images/fck_filesimages/2_Exellence.jpg"
                              alt="Excellence"
                              className="w-full h-full object-cover aspect-video lg:aspect-square"
                            />
                          </div>
                          <div className="w-full lg:w-9/12 shadow rounded-3xl flex flex-col justify-center p-8 lg:p-14"id="section3" data-aos="fade-up"  data-aos-delay="300">
                            <h3 className="text-3xl capitalize  font-semibold">
                              Excellence
                            </h3>

                            <p className=" text-lg py-5">
                              We promote excellence both individually and
                              collectively. Our dedication to continuous
                              improvement fosters personal growth and drives
                              achievement.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-6" id="section4" data-aos="fade-up"  data-aos-delay="400">
                          <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden">
                            <img
                              src="https://shrieducare.com/assets/images/fck_filesimages/3_innovation.jpg"
                              alt="innovation"
                              className="w-full h-full object-cover aspect-video lg:aspect-square"
                            />
                          </div>
                          <div className="w-full lg:w-9/12 shadow rounded-3xl flex flex-col justify-center p-8 lg:p-14" id="section4" data-aos="fade-up"  data-aos-delay="400">
                            <h3 className="text-3xl capitalize  font-semibold">
                              Innovation
                            </h3>

                            <p className=" text-lg py-5">
                              We foster creativity and encourage innovative
                              thinking across all aspects of our organization.
                              Embracing new ideas propels us towards success.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col md:flex-row-reverse gap-6" id="section5" data-aos="fade-up"  data-aos-delay="500">
                          <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden">
                            <img
                              src="https://shrieducare.com/assets/images/fck_filesimages/4_speed.jpg"
                              alt=""
                              className="w-full h-full object-cover aspect-video lg:aspect-square"
                            />
                          </div>
                          <div className="w-full lg:w-9/12 shadow rounded-3xl flex flex-col justify-center p-8 lg:p-14" id="section5" data-aos="fade-up"  data-aos-delay="500">
                            <h3 className="text-3xl capitalize  font-semibold">
                              Speed
                            </h3>

                            <p className=" text-lg py-5">
                              We proactively respond to all our stakeholders’
                              needs thru better upstream planning and efficient
                              systems and processes.
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

        
                  <section className="max-w-screen-xl mx-auto ">
                    <div className="flex flex-col gap-6">
                      <div className="flex flex-col md:flex-row gap-6">
                        <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden" id="section6" data-aos="fade-up"  data-aos-delay="600">
                          <img
                            src="https://shrieducare.com/assets/images/fck_filesimages/5_integrity.jpg"
                            alt="integerity"
                            className="w-full h-full object-cover aspect-video lg:aspect-square"
                          />
                        </div>
                        <div className="w-full lg:w-9/12 shadow rounded-3xl flex flex-col justify-center p-8 lg:p-14" id="section5" data-aos="fade-up"  data-aos-delay="500">
                          <h3 className="text-3xl capitalize  font-semibold">
                            Integrity
                          </h3>

                          <p className=" text-lg py-5">
                            We ensure transparency in all our transactions and
                            do not indulge in any action that brings disrepute
                            to the organization.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row-reverse gap-6">
                        <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden" id="section7" data-aos="fade-up"  data-aos-delay="700">
                          <img
                            src="https://shrieducare.com/assets/images/fck_filesimages/6_respect.jpg"
                            alt="respect"
                            className="w-full h-full object-cover aspect-video lg:aspect-square"
                          />
                        </div>
                        <div className="w-full lg:w-9/12 shadow rounded-3xl flex flex-col justify-center p-8 lg:p-14" id="section7" data-aos="fade-up"  data-aos-delay="700">
                          <h3 className="text-3xl capitalize  font-semibold">
                            Respect
                          </h3>

                          <p className=" text-lg py-5">
                            We treat every stakeholder with respect and dignity
                            and encourage everyone to express their opinions
                            frankly.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-6">
                        <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden" id="section8" data-aos="fade-up"  data-aos-delay="800">
                          <img
                            src="https://shrieducare.com/assets/images/fck_filesimages/7_well-being.jpg"
                            alt="Well-being"
                            className="w-full h-full object-cover aspect-video lg:aspect-square"
                          />
                        </div>
                        <div className="w-full lg:w-9/12 shadow rounded-3xl flex flex-col justify-center p-8 lg:p-14" id="section8" data-aos="fade-up"  data-aos-delay="800">
                          <h3 className="text-3xl capitalize  font-semibold">
                            Well-being
                          </h3>

                          <p className=" text-lg py-5">
                            We create a safe and happy work place for our
                            employees and stand by them in their times of need
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="leadership-teams" className="container mx-auto px-4 lg:px-40">
        <div>
          <div>
            <div className="text-center md:text-left py-10">
              <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">
                <span> Leadership</span>
              </h3>
            </div>

            <p className="text-left text-md leading-8 text-gray-600  w-full md:w-auto ">
              Team School Serv is passionate about increasing educational
              outcomes, achieving better results for our partner schools –
              results that go beyond financial and are social, holistic and
              uniquely tailored.
            </p>

            <div className="text-center md:text-left">
              <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900 py-10">
                <span> International School Consultants</span>
              </h3>
            </div>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
              transition={{ duration: 1 }}
              whileHover={{ scale: 1 }}
              className="transform transition duration-2000 hover:scale-105"
            >
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-3">
                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3 text-md">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Vinod_Kakumanu.png"
                      alt="Image 1"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Vinod Kakumanu</h3>
                  <p className="text-md">Lead Consultant & Founder</p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Subhashini.png"
                      alt="Image 2"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Subhashini</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Chief - Administration
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Lekha.png"
                      alt="Image 3"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Lekha</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Chief - Operations
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Greg_Parry.png"
                      alt="Image 4"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Greg Parry</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Senior Consultant (GSE CEO)
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Shanna_Parry.png"
                      alt="Image 5"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Shanna Parry</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Senior Consultant (GSE Managing Partner)
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Dr_Stewart_Saint_David.png"
                      alt="Image 6"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Dr.StewartSaint David</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Specialist Consultant Middle School, Secondary School,
                    Curriculum, Marketing and Recruitment
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Brent_Werry.png"
                      alt="Image 7"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">BrentWerry</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Specialist Consultant Early Childhood, Middle School,
                    Facilities and Design
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
              transition={{ duration: 1 }}
              whileHover={{ scale: 1 }}
              className="transform transition duration-2000 hover:scale-105"
            >
              <div className="text-center md:text-left">
                <div className="">
                  <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900 py-10 ">
                    <span className="text-"> Strategic Advisory Board</span>
                  </h3>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 pb-10">
                <div className="text-center border border-gray-300  hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Bimal_Nair.png"
                      alt="Image 1"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Bimal Nair</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Expert Advisor – Branding & Marketing
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Gary_Smejkal.png"
                      alt="Image 2"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Gary Smejkal</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    University of New Hampshire, Affiliate Assistant Professor
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Hilla_Rudanko.png"
                      alt="Image 3"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Hilla Rudanko</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    CEO, Partner, Architects Rudanko + Kankkunen Ltd. Helsinki
                    Area, Finland
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Dr_Jeane_Bosco_Tagne.png"
                      alt="Image 4"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Dr Jeane-Bosco Tagne</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Assistant Professor of Molecular Medicine
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Dr_Srikanth_Kakumanu.png"
                      alt="Image 5"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Dr Srikanth Kakumanu.</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Expert Advisor - STEM Curriculum
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Kunal_Sinha.png"
                      alt="Image 6"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Kunal Sinha</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Glowroad.com, Founder & CEO
                  </p>
                </div>
                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/N_Raja_Sujith.png"
                      alt="Image 7"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">N.Raja-Sujith</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Partner and Head of South India Majumdar
                  </p>
                </div>

                <div className="text-center border border-gray-300 hover:border-[#73BD35] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                  <div className="flex justify-center items-center mb-3">
                    <img
                      src="https://www.schoolserv.in/media/testinomial/Tulasi_Babu_G.png"
                      alt="Image 6"
                      className="h-40 rounded-full"
                    />
                  </div>
                  <h3 className="font-bold text-md">Tulasi Babu G</h3>
                  <p className=" text-md leading-8 text-gray-600 ">
                    Expert Advisor - Academics
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowUs;
