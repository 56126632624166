import React from "react";
import blog from "./Assets/Images/blog.png";
const Innovation: React.FC = () => {
  return (
    <div>
      <div className="relative inset-0">
        <div>
          <div>
            <img
              src="https://www.shrieducare.com/assets/images/banner_images/contact-us.jpg"
              className="w-full h-auto bg-gradient-to-l from-black  via-black to-transparent"
              alt="Background"
            />
            <div className="absolute inset-0 bg-gradient-to-l from-transparent via-transparent to-black"></div>
          </div>

          <div className="absolute top-[18rem]">
            <img src={blog} className="w-full h-auto" alt="Contact Us Banner" />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 py-20">
        <div className="col-span-12 md:col-span-9  z-[5]">
          <div className="bg-white mb-4 flex flex-col md:flex-row md:px-8 ">
            <img
              src="https://www.schoolserv.in/media/banner_image/About-Us.jpg"
              alt="Left Image"
              className="mr-4 md:mr-8 mb-4 md:mb-0 max-w-full md:max-w-none"
            />

            <div className="text-center md:text-justify">
              <h1 className="text-3xl md:text-3xl lg:text-3xl font-bold mb-5">
                Leading with Innovation
              </h1>
              <div className="mb-10 ">
                <hr className="border-b-2 border-indigo-500 w-10 md:w-20 lg:w-20  text-start" />
              </div>

              <div className="text-justify max-w-3xl">
                <p className="mb-6">
                  We are India’s leading K-12 school consultancy and the only
                  educational services provider with in-house capability to
                  cover the entire spectrum of the K-12 school establishment and
                  management needs. The dimensions of School Serv‘s outreach are
                  unmatched and so is the level of ownership and engagement that
                  we have demonstrated with every school we established in
                  India.
                </p>
                <p className="mb-6">
                  We have furthered the interest of education by resourceful
                  leveraging of technology and creating our own specialized
                  platforms to cover critical aspects of school management, set
                  up, and improvement.
                </p>
                <p className="mb-6">
                  While our core services are intensive and enrich select school
                  partners, our online services are extensive and reach lakhs of
                  schools across India. Platforms for teacher recruitment,
                  school real estate, marketplace for school supplies, and
                  school ERP systems aid schools of all scales in achieving time
                  and cost efficiency in their operations.
                </p>
                <p className="mb-6">
                  We believe and invest in research and development and have the
                  distinction of bringing at least one innovation in the
                  educational systems scene every year.
                </p>
                <p className="mb-6">
                  Our claim to fame is our dynamism. We assist schools secure
                  their place in the educational ecosystem of the 21st century
                  with changing tactics and unchanging principles.
                </p>
                <p className="mb-6">
                  As India's leading K-12 school lifecycle Services Company, we
                  take up new school projects and begin with market assessment.
                  A detailed project report is furnished followed by
                  infrastructural planning, academic program finalization and
                  implementation, branding and marketing strategy design,
                  teacher-recruitment and training, and procurement. Post
                  establishment, we closely monitor the school management
                  processes and optimize them through manpower training and the
                  use of technology.
                </p>
                <p className="mb-6">
                  The widest possible array of school consultancy and management
                  services require a dedicated and dynamic team. We have school
                  project Consultants, Academicians, Recruiters, Trainers,
                  Architects, Brand Specialists, Marketing Specialists, Project
                  Managers, Software Engineers, Chartered Accountants and a
                  large selection of school suppliers at our disposal to deliver
                  complete spectrum of services and make certain your experience
                  of school enterprising with us is an exemplary one.
                </p>
                <p className="mb-6">
                  For the establishment of International Baccalaureate® (IB)
                  World Schools in India, we collaborate with eminent
                  International school consultants on our mandate. We have
                  resourcefully deployed the services of internationally
                  acclaimed school architects, school master planners, and
                  learning environment interior designers to create 21st-century
                  school concepts.
                </p>
              </div>
              <ul className="list-disc px-4 md:px-9 text-justify max-w-3xl">
                <li className="mb-6">
                  <h3 className="text-lg font-semibold">
                    Global Services in Education
                  </h3>

                  <p className="text-gray-700">
                    Headquartered in Las Vegas, Nevada, United States with
                    project delivery offices in Beijing, China and Kuala Lumpur,
                    Malaysia
                  </p>
                </li>
                <li className="mb-6">
                  <h3 className="text-lg font-semibold">
                    Architects Rudanko + Kankkunen Ltd
                  </h3>
                  <p className="text-gray-700">Helsinki, Finland</p>
                </li>
                <li className="mb-6">
                  <h3 className="text-lg font-semibold">School Serv Designs</h3>
                  <p className="text-gray-700">Bangalore, Karnataka, India</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-4 py-5 lg:px-10 md:px-0 max-w-6xl mx-auto px-4">
            <div className="bg-white md:flex md:justify-between sm:justify-center">
              <div className="md:w-1/2 mt-8 md:mt-0">
                <h2 className="text-2xl md:text-2xl font-bold py-3 md:py-5 text-center sm:text-left">
                  Vision
                </h2>
                <div className="flex justify-center sm:justify-start p-1">
                  <hr className="border-b-2 border-indigo-500  sm:w-10 lg:w-10 " />
                </div>

                <p className="text-base md:text-lg max-w-sm px-4 md:px-0 py-5">
                  Enrich education for children in schools and prepare them for
                  life
                </p>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0">
                <h2 className="text-2xl md:text-3xl font-bold py-3 md:py-5 text-center sm:text-left">
                  Mission
                </h2>
                <div className="flex justify-center sm:justify-start">
                  <hr className="border-b-2 border-indigo-500 w-10 sm:w-20 lg:w-20" />
                </div>
                <p className="text-base md:text-lg px-4 md:px-0 py-5">
                  Re-imagine school education – To establish sustainable
                  schools, enhance teaching opportunities and educate children
                  for life
                </p>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h2 className="text-center mb-4 text-3xl font-bold">Core Values</h2>
            <div className="flex justify-center">
              <hr className="border-b-2 border-indigo-500 w-20 md:w-40" />
            </div>

            <div className="flex flex-wrap justify-center md:justify-around">
              <div className="md:w-1/2 lg:w-1/4 mr-6 mb-6 md:mb-0 text-center md:text-left">
                <ul className="list-disc">
                  <li className="text-lg mb-2">
                    Innovation in 21st-century school concepts.
                  </li>
                  <li className="text-lg">
                    Integrity and openness in all interactions.
                  </li>
                </ul>
              </div>
              <div className="md:w-1/2 lg:w-1/4 mr-6 mb-6 md:mb-0 text-center md:text-left">
                <ul className="list-disc">
                  <li className="text-lg mb-2">
                    Dedication to every client’s success.
                  </li>
                  <li className="text-lg">
                    Trust and personal responsibility in all relationships.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mb-4 bg-[#fafafa]">
            <div className="max-w-6xl mx-auto px-4">
              <h2 className="text-center mb-4 text-3xl font-bold py-5">
                Why School Serv ?
              </h2>
              <div className="flex justify-center">
                <hr className="border-b-2 border-indigo-500 w-20 md:w-40" />
              </div>

              <p className="py-5 text-center">
                School Serv has helped clients launch schools in a variety of
                social settings, market scenarios, and budgets. We have
                established networks to deliver necessary services, technology,
                materials, supplies, establish curriculum, set school policies,
                and deliver a high-quality educational experience to all
                students.
              </p>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 justify-items-center">
                <div className="px-4 mb-8">
                  <div className="text-center">
                    <img
                      src="https://www.schoolserv.in/static/images/header_img/icon_01.png"
                      alt="Image Alt Text"
                      className="mx-auto w-20 h-auto"
                    />
                    <p className="mt-2">
                      Comprehensiveness is our hallmark, we outsource absolutely
                      nothing
                    </p>
                  </div>
                </div>

                <div className="px-4 mb-8">
                  <div className="text-center">
                    <img
                      src="https://www.schoolserv.in/static/images/header_img/icon_02.png"
                      alt="Image Alt Text"
                      className="mx-auto w-20 h-auto"
                    />
                    <p className="mt-2">
                      We lead with Innovation and pride ourselves as exemplars
                      of adoption of original ideas and technology in education
                      services
                    </p>
                  </div>
                </div>

                <div className="px-4 mb-8">
                  <div className="text-center">
                    <img
                      src="https://www.schoolserv.in/static/images/header_img/icon_03.png"
                      alt="Image Alt Text"
                      className="mx-auto w-20 h-auto"
                    />
                    <p className="mt-2">
                      Deep insights into the K-12 education sector help us
                      perfectly interpret developments and adapt
                    </p>
                  </div>
                </div>

                <div className="px-4 mb-8">
                  <div className="text-center">
                    <img
                      src="https://www.schoolserv.in/static/images/header_img/icon_04.png"
                      alt="Image Alt Text"
                      className="mx-auto w-20 h-auto"
                    />
                    <p className="mt-2">
                      The highest levels of Ownership and Accountability make us
                      stand apart
                    </p>
                  </div>
                </div>

                <div className="px-4 mb-8">
                  <div className="text-center">
                    <img
                      src="https://www.schoolserv.in/static/images/header_img/icon_05.png"
                      alt="Image Alt Text"
                      className="mx-auto w-20 h-auto"
                    />
                    <p className="mt-2">
                      Our experience in handling challenging and complex
                      assignments
                    </p>
                  </div>
                </div>

                <div className="px-4 mb-8">
                  <div className="text-center">
                    <img
                      src="https://www.schoolserv.in/static/images/header_img/icon_06.png"
                      alt="Image Alt Text"
                      className="mx-auto w-20 h-auto"
                    />
                    <p className="mt-2">
                      We create excellence regardless of location and scale
                    </p>
                  </div>
                </div>

                <div className="px-4 mb-8">
                  <div className="text-center">
                    <img
                      src="https://www.schoolserv.in/static/images/header_img/icon_07.png"
                      alt="Image Alt Text"
                      className="mx-auto w-20 h-auto"
                    />
                    <p className="mt-2">
                      We truly collaborate with our partners and not limit
                      ourselves to detached advisory
                    </p>
                  </div>
                </div>

                <div className="px-4 mb-8">
                  <div className="text-center">
                    <img
                      src="https://www.schoolserv.in/static/images/header_img/icon_08.png"
                      alt="Image Alt Text"
                      className="mx-auto w-20 h-auto"
                    />
                    <p className="mt-2">
                      We implement learning programs for the development of
                      21st-century skills with demonstrably high learning
                      outcomes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4  max-w-6xl mx-auto px-4">
            <div className="text-center md:text-left">
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 md:mb-6 w-full md:w-auto">
                Leadership
              </h1>
              <div className="flex justify-center sm:justify-start">
                <hr className="border-b-2 border-indigo-500 w-10 md:w-20 lg:w-40" />
              </div>
            </div>

            <p className="text-left w-full md:w-auto py-5">
              Team School Serv is passionate about increasing educational
              outcomes, achieving better results for our partner schools –
              results that go beyond financial and are social, holistic and
              uniquely tailored.
            </p>

            <div className="text-center md:text-left">
              <h1 className="text-xl md:text-lg lg:text-xl font-bold mb-4 md:mb-6 w-full md:w-auto">
                International School Consultants
              </h1>
              <div className="flex justify-center sm:justify-start">
                <hr className="border-b-2 border-indigo-500 w-10 md:w-20 lg:w-40" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-3">
              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Vinod_Kakumanu.png"
                    alt="Image 1"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Vinod Kakumanu</h3>
                <p>Lead Consultant & Founder</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Subhashini.png"
                    alt="Image 2"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Subhashini</h3>
                <p>Chief - Administration</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Lekha.png"
                    alt="Image 3"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Lekha</h3>
                <p>Chief - Operations</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Greg_Parry.png"
                    alt="Image 4"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Greg Parry</h3>
                <p>Senior Consultant (GSE CEO)</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Shanna_Parry.png"
                    alt="Image 5"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Shanna Parry</h3>
                <p>Senior Consultant (GSE Managing Partner)</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Dr_Stewart_Saint_David.png"
                    alt="Image 6"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Dr.StewartSaint David</h3>
                <p>
                  Specialist Consultant Middle School, Secondary School,
                  Curriculum, Marketing and Recruitment
                </p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Brent_Werry.png"
                    alt="Image 7"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">BrentWerry</h3>
                <p>
                  Specialist Consultant Early Childhood, Middle School,
                  Facilities and Design
                </p>
              </div>
            </div>
            <div className="text-center md:text-left">
              <div className="py-5">
                <h1 className="text-lg font-bold mb-4 md:mb-6 w-full md:w-auto">
                  Strategic Advisory Board
                </h1>
                <div className="flex justify-center sm:justify-start">
                  <hr className="border-b-2 border-indigo-500 w-10 md:w-20 lg:w-40" />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-3">
              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Bimal_Nair.png"
                    alt="Image 1"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Bimal Nair</h3>
                <p>Expert Advisor – Branding & Marketing</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Gary_Smejkal.png"
                    alt="Image 2"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Gary Smejkal</h3>
                <p>
                  University of New Hampshire, Affiliate Assistant Professor
                </p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Hilla_Rudanko.png"
                    alt="Image 3"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Hilla Rudanko</h3>
                <p>
                  CEO, Partner, Architects Rudanko + Kankkunen Ltd. Helsinki
                  Area, Finland
                </p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Dr_Jeane_Bosco_Tagne.png"
                    alt="Image 4"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Dr Jeane-Bosco Tagne</h3>
                <p>Assistant Professor of Molecular Medicine</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Dr_Srikanth_Kakumanu.png"
                    alt="Image 5"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Dr Srikanth Kakumanu.</h3>
                <p>Expert Advisor - STEM Curriculum</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Kunal_Sinha.png"
                    alt="Image 6"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Kunal Sinha</h3>
                <p>Glowroad.com, Founder & CEO</p>
              </div>
              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/N_Raja_Sujith.png"
                    alt="Image 7"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">N.Raja-Sujith</h3>
                <p>Partner and Head of South India Majumdar</p>
              </div>

              <div className="text-center border border-gray-300 hover:border-[#d1ae84] p-4 hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div className="flex justify-center items-center mb-3">
                  <img
                    src="https://www.schoolserv.in/media/testinomial/Tulasi_Babu_G.png"
                    alt="Image 6"
                    className="h-40 rounded-full"
                  />
                </div>
                <h3 className="font-bold">Tulasi Babu G</h3>
                <p>Expert Advisor - Academics</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-3 overflow-hidden lg:max-h-[429vh] md:max-h[429vh] sm:max-h-10 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100  z-[5]">
          <div className="bg-white rounded mb-4">
            <h1 className="text-2xl font-bold mb-5 lg:text-left">
              Popular on Founder's Blog
            </h1>
            <div className="mb-10 flex justify-left">
              <hr className="border-b-2 border-indigo-500 w-10 md:w-20 lg:w-40" />
            </div>

            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button
                      type="submit"
                      className="  text-black font-bold py-2 px-4 rounded"
                    >
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="bg-white rounded mb-4">
            <div className="recent-post-info flex items-center">
              <div className="pr-0">
                <img
                  src="https://www.schoolserv.in/media/feature_image/School_education_in_Proddatur261x182_gbA7MQV.jpg"
                  alt="School Management"
                  className="w-20 h-20 float-left mr-4"
                />
              </div>
              <div className="flex-1">
                <a href="/Assam-registers-nearly-6-decline-in-school-enrollment/">
                  <h3 className="popular_blog_img_mob">
                    <button type="submit" className="popular_butn">
                      Assam registers nearly 6% decline in school enrollment
                    </button>
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Innovation;
